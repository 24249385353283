import { editHarvest } from 'api/harvest'
import { FormControlLabel, TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { closeModal } from 'actions/app'
import ModalTool from '../modal/modal-tool'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect, useState, ReactElement } from 'react'

import { AppState } from 'state'

declare module 'components/change-harvest-year' {
  type ChangeHarvestYearProps = {}
}

const StyledTextField = withStyles({
  root: {
    width: '25ch',
    padding: '10px',
  },
})(TextField)

// TODO: this can be done using a Set with ES6
function onlyUnique(value, index, array) {
  return array.indexOf(value) === index
}

export default function ChangeHarvestYear(): ReactElement<any, any> {
  const [newCropYear, setNewCropYear] = useState<number>(0)
  const [availableCropYears, setAvailableCropYears] = useState<number[]>([])
  const [currentCropYear, setCurrentCropYear] = useState<number>(1970)
  const harvest = useSelector((state: AppState) => state.harvest.current)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  useEffect(() => {
    if (harvest) {
      setCurrentCropYear(harvest.cropYear)
      // set available crop years to be the year(s) in the start and end dates,
      // or the year previous to the start date if the start date and end date are
      // in the same year
      const cropYears: number[] = []
      cropYears.push(harvest.cropYear)
      const startDate = new Date(harvest.startDate)
      if (startDate.getFullYear() !== harvest.cropYear) {
        cropYears.push(startDate.getFullYear())
      }
      const endDate = new Date(harvest.endDate)
      if (startDate.getFullYear() !== endDate.getFullYear()) {
        cropYears.push(endDate.getFullYear())
      } else {
        cropYears.push(startDate.getFullYear() - 1)
      }
      let uniqueCropYears = cropYears.filter(onlyUnique)
      setAvailableCropYears(uniqueCropYears)
    }
  }, [harvest])

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const onChange = useCallback(
    (e) => {
      setNewCropYear(parseInt(e.target.value))
    },
    [newCropYear],
  )

  const onChangeHarvestYear = useCallback(() => {
    if (harvest) {
      harvest.cropYear = newCropYear
      dispatch(editHarvest(harvest?.id, harvest, false))
      dispatch(closeModal())
    }
  }, [harvest, newCropYear])

  return (
    <ModalTool
      header={t('edit_crop_year')}
      content={
        <>
          <FormControlLabel
            control={
              <StyledTextField
                id="current-crop-year"
                value={currentCropYear}
                name="currentCropYear"
                variant="outlined"
                size="small"
                inputProps={{ disabled: true }}
              />
            }
            label={t('current_crop_year')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <StyledTextField
                id="change-crop-year"
                select
                value={newCropYear}
                // defaultValue={availableCropYears[0]}
                name="changeCropYear"
                variant="outlined"
                size="small"
                SelectProps={{ native: true }}
                onChange={onChange}
              >
                {availableCropYears.map((innerItem: number) => (
                  <option key={innerItem} value={innerItem}>
                    {innerItem}
                  </option>
                ))}
              </StyledTextField>
            }
            label={t('new_crop_year')}
            labelPlacement="start"
          />
        </>
      }
      onClose={closePopup}
      onApply={onChangeHarvestYear}
    />
  )
}
