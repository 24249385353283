import { Alert, Box } from '@mui/material'
import { closeModal } from 'actions/app'
import Checkbox from '@mui/material/Checkbox'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import { getYMIDHeaderHarvestSummary, updateHarvestHeader } from 'api/harvest'
import { uniqWith, isEqual, filter, uniq, cloneDeep, find } from 'lodash-es'
import { convertToFeetInches } from 'common/unitConvert'
import ModalTool from 'components/modal/modal-tool'
import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppState } from 'state'
import { Feature } from 'geojson'
import { HeaderModel } from 'state/headers'
import { YMIdHeader } from 'state/harvest'
const useStyles = makeStyles((theme) => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  //@ts-expect-error
  button: { margin: theme.spacing(1) },
  borderBottomCustom: { borderBottom: 'none' },
  customFontSize: { fontSize: '1rem' },
  requiredField: { color: 'red' },
}))
/*
            id: '',
            name: '',
            makeBrandId: null,
            year: null,
            headerType: '',
            units: 'm',
            cutWidth: ymIDHeader.ymHeader.headerWidthMeters,,*/

type YMHeaderItem = {
  fromES?: boolean
  percentage?: number
  widthin?: number | string
  widthft?: number | string
  cutWidth: number
  id: string
  name: string
}
type HeaderRowItem = {
  id: string
  ymID: string
  ymHeader: YMHeaderItem[]
  nickname: string
}

type HeaderSelection = {
  combineId: string
  harvestId: number
  headerId: string
  headerWidthMeters: number
  ymId: string
}

export default function SetHeader() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { t } = useTranslation()

  const combines = useSelector((state: AppState) => state.equipment.collection)
  const headers = useSelector((state: AppState) => state.headers.collection)
  const harvest = useSelector((state: AppState) => state.harvest.current)
  const fieldFeatures: Feature[] | undefined = useSelector(
    (state: AppState) => state.field.features,
  )
  const hardware = useSelector((state: AppState) => state.hardware.collection)
  const userPrefs = useSelector(
    (state: AppState) => state.account.singleton.prefs,
  )
  const prefsUnit =
    userPrefs?.units?.length_in === 'meters' ||
    userPrefs?.units?.length_in === undefined
      ? 'm'
      : 'ftIn'

  const ymIDHeaders: YMIdHeader[] = useSelector(
    (state: AppState) => state.harvest?.current?.ymIDHeaders || [],
  )

  const [selectedRows, setSelectedRows] = useState<HeaderSelection[]>([])
  const [header, setHeader] = useState({})
  const [showAlert, setShowAlert] = useState(false)
  const [showNoHeaderHarvestData, setShowNoHeaderHarvestData] = useState(false)
  const [headerUpdateFail, setHeaderUpdateFail] = useState(false)
  const [selected, setSelected] = useState<string[]>([])
  const [filteredYMIDs, setFilteredYMIDs] = useState<HeaderRowItem[]>([])

  useEffect(() => {
    if (fieldFeatures && fieldFeatures[0]?.geometry && harvest) {
      dispatch(
        getYMIDHeaderHarvestSummary(harvest.id, {
          range: {
            startDate: harvest.startDate,
            endDate: harvest.endDate || null,
          },
          //@ts-expect-error no idea why it doesn't like feature.geometry.coordinates, feature should have a geometry
          //which should have coordinates.first two are fine, coordinates is not
          fieldBoundary: fieldFeatures[0]?.geometry?.coordinates,
        }),
      )
    }
  }, [])

  const toFeetInches = useCallback((val) => {
    if (val != '') {
      const { feet, inches } = convertToFeetInches(val)
      return {
        widthin: inches,
        widthft: feet,
      }
    } else {
      return {
        widthin: '',
        widthft: '',
      }
    }
  }, [])

  useEffect(() => {
    let yms: HeaderRowItem[] = []
    let filteredYMs = filter(hardware, ['hardwareType', 'YieldMonitor'])
    let defaultValues = []
    let headerItems: YMHeaderItem[] = []
    headers.map((item) => {
      const { widthin, widthft } = toFeetInches(item.cutWidth)
      headerItems.push({
        fromES: false,
        percentage: 0,
        widthin: widthin,
        widthft: widthft,
        cutWidth: item.cutWidth,
        id: item.id,
        name: item.name,
      })
    })
    /*variable ymIDHeaders => the '/header-summary' response from the OpenSearch */
    if ((ymIDHeaders && ymIDHeaders.length) > 0) {
      ymIDHeaders.map((ymIDHeader) => {
        let obj: HeaderRowItem = {
          id: ymIDHeader.ymHeader.ymID,
          ymID: '',
          ymHeader: [],
          nickname: '',
        }
        obj.ymID = ymIDHeader.ymHeader.ymID
        /*Filter with Hardware that are avilable in the FarmSetup page. More testing will happen when we sync mobile and web.*/
        let filteredYMIDObjs = filter(filteredYMs, [
          'farmtrxHardwareId',
          ymIDHeader.ymHeader.ymID,
        ])
        filteredYMIDObjs.forEach((item) => {
          obj = { ...filter(combines, ['id', item.equipmentId])[0], ...obj }
        })
        obj.ymHeader = []
        ymIDHeader.ymHeader.headerWidthMeters = parseFloat(
          ymIDHeader.ymHeader.headerWidthMeters.toFixed(2),
        )
        /*
        Check if any header width in the FarmSetup page is matching to any header width from the '/header-summary' call response. 
        If there are more than one headers with the same header widths, take only the first occurrence. 
        */
        let filteredHeader: YMHeaderItem | undefined = find(headerItems, [
          'cutWidth',
          ymIDHeader.ymHeader.headerWidthMeters,
        ])
        let filteredHeaders: YMHeaderItem[] = []
        if (filteredHeader) {
          const { widthin, widthft } = toFeetInches(filteredHeader.cutWidth)
          filteredHeaders.push({
            fromES: true,
            percentage: ymIDHeader.ymHeader.percentage,
            widthin: widthin,
            widthft: widthft,
            cutWidth: filteredHeader.cutWidth,
            id: filteredHeader.id,
            name: filteredHeader.name,
          })
        }
        if (filteredHeaders.length == 0) {
          const { widthin, widthft } = toFeetInches(
            ymIDHeader.ymHeader.headerWidthMeters,
          )
          obj.ymHeader.push({
            id: obj.ymID,
            name: '',
            fromES: true,
            percentage: ymIDHeader.ymHeader.percentage,
            cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
            widthin: widthin,
            widthft: widthft,
          })
          defaultValues[obj.ymID] = {
            id: obj.ymID,
            cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
          }
        } else {
          filteredHeaders.map((fh) => {
            fh.percentage = ymIDHeader.ymHeader.percentage
            obj.ymHeader.push(fh)
            defaultValues[obj.id] = {
              id: obj.ymID,
              cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
            }
          })
        }
        //add the headers that are there in the farm setup page
        obj.ymHeader = uniq([...obj.ymHeader, ...headerItems])
        /* If the header widths are matching, show the farmSetup record in red color.*/
        if (filteredHeaders.length > 0) {
          let ind = obj.ymHeader.findIndex(
            (s) => s.id === filteredHeaders[0]?.id,
          )
          obj.ymHeader[ind].fromES = true
        }
        if (obj.id == undefined) {
          obj.id = obj.ymID
        }
        /*
        Add further records from the OpenSearch, '/header-summary' response for the same YMID. 
        Add only when there is no match with header widths in the FarmSetup.
        */
        if (yms.length && yms.filter((s) => s.ymID === obj.ymID).length) {
          let ymIDIndex = yms.findIndex((s) => s.ymID === obj['ymID'])
          const { widthin, widthft } = toFeetInches(
            ymIDHeader.ymHeader.headerWidthMeters,
          )
          if (filteredHeaders.length == 0) {
            yms[ymIDIndex].ymHeader.push({
              fromES: true,
              percentage: ymIDHeader.ymHeader.percentage,
              cutWidth: ymIDHeader.ymHeader.headerWidthMeters,
              id: obj.ymID,
              name: obj.ymID,
              widthin: widthin,
              widthft: widthft,
            })
          }
        } else {
          yms.push(obj)
        }
      })
      setFilteredYMIDs(yms)
      setHeader(defaultValues)
    } else {
      setShowNoHeaderHarvestData(true)
    }
  }, [ymIDHeaders, hardware, combines])

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const selectHeader = useCallback(
    (e, row) => {
      const rowIndex = selected.findIndex((p) => p === row.id)
      if (e.type == 'change' && rowIndex == -1) {
        setShowAlert(false)
        let selectedHeader = header[row.id]
        let width = row.ymHeader[0].cutWidth
        let headerId = row.ymHeader[0].id
        if (selectedHeader != undefined) {
          width = selectedHeader.cutWidth
          headerId = selectedHeader.id
        }
        let obj: HeaderSelection = {
          combineId: row.id,
          //tell typescript that harvest will be defined
          harvestId: harvest!.id,
          ymId: row.ymID,
          headerId: headerId,
          headerWidthMeters: width,
        }
        setSelected([...selected, row.id])
        setSelectedRows([...selectedRows, obj])
      } else {
        const index = selectedRows.findIndex((p) => p.ymId === row.ymID)
        if (rowIndex > -1) {
          let newRows = [...selected]
          newRows.splice(rowIndex, 1)
          setSelected(newRows)
        }
        if (index > -1) {
          let newRows = cloneDeep(selectedRows)
          newRows.splice(index, 1)
          setSelectedRows(newRows)
        } else setSelectedRows([])
      }
    },
    [header, selectedRows, selected],
  )

  const handleHeaderChange = useCallback(
    (event, row) => {
      event.stopPropagation()
      let _header = { ...header }
      const newHeader = row.ymHeader.find(
        (e) => `${e['id']} - ${e['cutWidth']}` === event.currentTarget.value,
      )
      if (newHeader) {
        _header[row.id] = newHeader
        setHeader(_header)
        const _selectedRows = [...selectedRows]
        const index = _selectedRows.findIndex((r) => r.combineId == row.id)
        if (index > -1) {
          _selectedRows[index].headerWidthMeters = newHeader.cutWidth
          _selectedRows[index].headerId = newHeader.id
          setSelectedRows(_selectedRows)
        }
      }
    },
    [header, headers, selectedRows],
  )

  const setSelectedHeaderToHarvest = useCallback(() => {
    const finalRows = uniqWith(selectedRows, isEqual)
    if (finalRows.length) {
      setShowAlert(false)

      finalRows.map((row) => {
        //@ts-expect-error
        dispatch(updateHarvestHeader(harvest.id, row)).then((res) => {
          if (res === 'failed') {
            setHeaderUpdateFail(true)
          } else {
            dispatch(closeModal())
          }
        })
      })
    } else {
      setShowAlert(true)
    }
  }, [selectedRows])

  const isSelected = (id) => selected && selected.indexOf(id) !== -1

  const selectAllHeaders = (event) => {
    let allHeadersChecked = event.target.checked
    if (allHeadersChecked) {
      if (harvest) {
        let addRows: HeaderSelection[] = []
        let newSelections: string[] = []
        filteredYMIDs.map((n) => {
          let selectedHeader = header[n.ymID]
          let headerId = n.ymHeader[0].id
          let width = n.ymHeader[0].cutWidth
          if (selectedHeader != undefined) {
            width = selectedHeader.cutWidth
            headerId = selectedHeader.id
          }
          newSelections.push(n.id)
          addRows.push({
            combineId: n.id,
            harvestId: harvest.id,
            ymId: n.ymID,
            headerId: headerId,
            headerWidthMeters: width,
          })
        })
        setSelectedRows(addRows)
        setSelected(newSelections)
      }
    } else {
      setSelectedRows([])
      setSelected([])
    }
  }

  return (
    <ModalTool
      header={t('edit_layer_header')}
      content={
        <>
          <p>{t('set_header_map_reprocess')}</p>

          <Box mx={1} my={1} sx={{ display: showAlert ? 'block' : 'none' }}>
            <Alert severity="warning">{t('empty_harvest_info')}</Alert>
          </Box>
          <Box
            mx={1}
            my={1}
            sx={{ display: headerUpdateFail ? 'block' : 'none' }}
          >
            <Alert severity="warning">{t('update_header_error')}</Alert>
          </Box>

          <TableContainer
            component={Paper}
            style={{
              overflowY: 'auto',
              maxHeight: '35vh',
            }}
          >
            <Table
              //className={classes.table}
              aria-label="set-header-table"
              stickyHeader
              style={{
                overflowY: 'auto',
              }}
            >
              <TableHead>
                <TableRow role="checkbox">
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={selectAllHeaders}
                      checked={selected.length === filteredYMIDs.length}
                    />
                  </TableCell>
                  <TableCell align="left">
                    {t('combine')}
                    <span className={classes.requiredField}>*</span>
                  </TableCell>
                  <TableCell align="left">
                    {t('header')}
                    <span className={classes.requiredField}>*</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                style={{
                  overflowY: 'auto',
                }}
              >
                {filteredYMIDs && filteredYMIDs.length > 0 ? (
                  filteredYMIDs.map((yieldMonitor) => {
                    const isItemSelected = isSelected(yieldMonitor.id)
                    return (
                      <TableRow
                        key={yieldMonitor.id}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        <TableCell
                          padding="checkbox"
                          className={classes.borderBottomCustom}
                        >
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            onChange={(e) => selectHeader(e, yieldMonitor)}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.borderBottomCustom}
                        >
                          <TextField
                            disabled
                            name="combine"
                            variant="outlined"
                            size="small"
                            placeholder={t('select_equipment')}
                            value={
                              yieldMonitor?.nickname?.trim() ||
                              yieldMonitor?.ymID
                            }
                          ></TextField>
                        </TableCell>

                        <TableCell
                          align="left"
                          className={classes.borderBottomCustom}
                        >
                          <>
                            <TextField
                              id="header"
                              name="header"
                              variant="outlined"
                              select
                              size="small"
                              placeholder={t('select_header')}
                              SelectProps={{ native: true }}
                              value={
                                `${header[yieldMonitor.id]?.id} - ${
                                  header[yieldMonitor.id]?.['cutWidth']
                                }` || yieldMonitor?.ymHeader[0]?.id
                              }
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                              }}
                              onChange={(e) =>
                                handleHeaderChange(e, yieldMonitor)
                              }
                            >
                              {yieldMonitor.ymHeader &&
                              yieldMonitor.ymHeader.length ? (
                                yieldMonitor.ymHeader.map((item, index) => {
                                  return (
                                    <option
                                      value={`${item['id']} - ${item['cutWidth']}`}
                                      key={index}
                                      style={{
                                        color: item['fromES'] ? 'red' : 'black',
                                      }}
                                    >
                                      {item?.name} (
                                      {prefsUnit == 'm'
                                        ? `${item['cutWidth'].toFixed(2)} m`
                                        : `${item.widthft}' ${item.widthin}"`}
                                      ){' '}
                                      {item['percentage']
                                        ? item['percentage'].toFixed(1) + ' %'
                                        : ''}
                                    </option>
                                  )
                                })
                              ) : (
                                <></>
                              )}
                            </TextField>
                          </>
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell>
                      <Box
                        mx={1}
                        my={1}
                        sx={{
                          display: showNoHeaderHarvestData ? 'block' : 'none',
                          width: 1,
                        }}
                      >
                        <Alert severity="info">{t('no_data')}</Alert>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      onClose={closePopup}
      onApply={setSelectedHeaderToHarvest}
    />
  )
}
