import { updateTimeDelay } from 'api/harvest'
import { FormControlLabel, TextField } from '@mui/material'
import { closeModal } from 'actions/app'
import withStyles from '@mui/styles/withStyles'
import ModalTool from '../modal/modal-tool'

const StyledTextField = withStyles({
  root: {
    width: '25ch',
    padding: '10px',
  },
})(TextField)

export default function ChangeTimeDelay() {
  const [val, setVal] = useState()
  const [selectedEquipment, setEquipment] = useState()

  const harvest = useSelector(
    (state) => state.harvest.current && state.harvest.current,
  )

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })

  const onChange = useCallback((e) => {
    setVal(e.target.value)
  }, [])

  const onAChangeTimeDelay = useCallback(() => {
    dispatch(
      updateTimeDelay(harvest.id, val, selectedEquipment, harvest.tenantId),
    )
  }, [harvest, val, selectedEquipment])

  let harvesterInfo = harvest?.stats?.harvester_info

  const hardware = useSelector((state) => state.hardware.collection)
  const equipment = useSelector((state) => state.equipment.collection)

  let equipmentArray = useMemo(() => {
    let _equipmentArray = []
    if (hardware && equipment && harvesterInfo) {
      for (let harvester in harvesterInfo) {
        const h = harvesterInfo[harvester]
        //if harvesterInfo has a ymId use it, if it doesn't then try to use whatever harvester info is indexed by.
        const ymId = h?.ymId || harvester
        let hwObj = hardware.find((hw) => hw?.farmtrxHardwareId === ymId)
        let eqObj =
          hwObj && equipment.find((eq) => eq.id === hwObj?.equipmentId)
        _equipmentArray.push({
          name: eqObj ? eqObj.nickname : ymId,
          ymId: ymId,
        })
      }
      setEquipment(_equipmentArray[0]?.ymId)
    }
    return _equipmentArray
  }, [hardware, equipment, harvesterInfo])

  const onChangeEquipment = useCallback((e) => {
    setEquipment(e.target.value)
  }, [])

  return (
    <ModalTool
      header={t('edit_time_delay')}
      content={
        <>
          <FormControlLabel
            control={
              <StyledTextField
                id="select-equipment"
                disabled={equipmentArray.length < 1}
                select
                name="equipment"
                value={selectedEquipment}
                onChange={onChangeEquipment}
                SelectProps={{ native: true }}
                variant="outlined"
                size="small"
                placeholder={t('select_equipment')}
              >
                {equipmentArray.map((equipment) => (
                  <option value={equipment.ymId} key={equipment.ymId}>
                    {equipment.name}
                  </option>
                ))}
              </StyledTextField>
            }
            label={t('equipment')}
            labelPlacement="start"
          />
          <FormControlLabel
            control={
              <StyledTextField
                id="changeTimeDelay"
                defaultValue={val}
                name="changeTimeDelay"
                variant="outlined"
                size="small"
                onChange={onChange}
              />
            }
            label={t('time_delay')}
            labelPlacement="start"
          />
        </>
      }
      onClose={closePopup}
      onApply={onAChangeTimeDelay}
    />
  )
}
