import http from 'common/http'
import { OAuth2AuthCodePkceClient } from 'oauth2-pkce'
import {
  integrationCreated,
  tenantIntegrationsFetched,
  integrationsFetched,
  setIntegrationUrls,
  setAllSyncStatus,
  setClientSyncStatus,
  setFarmSyncStatus,
  setFieldSyncStatus,
  setHarvestSyncStatus,
  setIntegrationSyncErrors,
} from 'actions/integrations'
import * as c from 'common/c'
import { clientsFetched } from 'actions/client'
import { farmsFetched } from 'actions/farm'
import { updateFeaturesOverview, fieldsFetched } from 'actions/field'
import { createJob, removeJob, updateJob } from 'actions/job'

import type { TenantIntegration, IntegrationData } from 'state/integrations'

import { get, isEmpty, pick } from 'lodash-es'
import store from 'store'
import { isTokenValid } from 'common/misc'

export type OpsCenterOrgResult = {
  links: OpsCenterLink[]
  total: number
  values: OpsCenterOrganization[]
}
export type OpsCenterOrganization = {
  '@type': string
  id: string
  internal: boolean
  links: OpsCenterLink[]
  member: boolean
  name: string
  type: string
}
export type OpsCenterLink = {
  '@type': string | undefined
  rel: string
  uri: string
}

export type IntegrationOnboarding = {
  id: number
  authType: string
  authorizationUrl: string
  baseUrl: string
  clientId: string
  name: string
  scopes: string[]
  tokenUrl: string
  createdAt: string | null
  updatedAt: string | null
  attempt: number
  approvalUrl?: string
}

//harvest is either a harvest Id or all
//fileUri: the uri to upload the file to
//token to use for the upload
//integrationId the id of the integration so that we can do any integration specific processing.
export type HarvestUploadData = {
  harvestId: string
  token: string
  integrationId: number
  year: number
  name: string
  organizationId: string
  clients: { [key: number]: ClientItem }
  farms: { [key: number]: FarmItem }
}

export type ClientItem = {
  name: string
  id: number
  integrationId: string
  archived: boolean
}

export type FarmItem = {
  name: string
  id: number
  integrationId: string
  clientId: number
  archived: boolean
}

export type FieldItem = {
  name: string
  id: number
  integrationId: string
  farmId: number
}

export type UrlRequestObj = {
  id: number
  baseUrl: string
  name: string
  token: string
  clients?: { [key: number]: ClientItem }
  farms?: { [key: number]: FarmItem }
  fields?: { [key: number]: FieldItem }
}

export type IntegrationLinkRequest = {
  integrationId: number
  customerId: string
  name: string
  integrationUrls: {
    [key: string]: { [key: string]: { [key: string]: string } }
  }
}

export const createIntegration = (data) => {
  return (dispatch) => {
    return http.post('/tenant-integration', data).then((res) => {
      dispatch(integrationCreated(res.data))
      return {
        message: 'Integration Linked',
        data: res.data,
      }
    })
  }
}

export const updateIntegration = (data, integrationId) => {
  return (dispatch) => {
    return http
      .put(`/tenant-integration/${integrationId}`, data)
      .then((res) => {
        dispatch(integrationCreated(res.data))
      })
  }
}

export const fetchTenantIntegrations = (availableIntegrations) => {
  try {
    let data: UrlRequestObj[] = []
    for (let i = 0; i < availableIntegrations.length; i++) {
      const authData = localStorage.getItem(availableIntegrations[i].name)

      if (authData) {
        const tokenData = JSON.parse(authData)
        const validToken = isTokenValid(tokenData?.accessToken)
        if (validToken) {
          data.push({
            id: availableIntegrations[i].id,
            baseUrl: availableIntegrations[i].baseUrl,
            token: tokenData.accessToken,
            name: availableIntegrations[i].name,
          })
        } else {
          localStorage.removeItem(availableIntegrations[i].name)
        }
      }
    }
    if (data.length > 0) {
      return (dispatch) => {
        return http.post('/tenant-integration/list', data).then((res) => {
          dispatch(tenantIntegrationsFetched(res.data.integrations))
          loadIntegrationData(res.data.integrationUrls)
        })
      }
    } else {
      return (dispatch) => {
        return http.post('/tenant-integration/list').then((res) => {
          dispatch(tenantIntegrationsFetched(res.data.integrations))
        })
      }
    }
  } catch (e) {
    console.log('integrations went wrong', e)
  }
}

export const fetchAvailableIntegrations = () => {
  return (dispatch) => {
    return http.get('/integration').then((res) => {
      dispatch(integrationsFetched(res.data))
      dispatch(fetchTenantIntegrations(res.data))
    })
  }
}

export const requestIntegrationCode = (
  authorizationUrl: string,
  scopes: string[],
  clientId: string,
  redirectUrl: string,
  tokenUrl: string,
) => {
  const oauthClient = new OAuth2AuthCodePkceClient({
    scopes: scopes,
    authorizationUrl: authorizationUrl,
    clientId: clientId,
    redirectUrl: redirectUrl,
    tokenUrl: tokenUrl,
  })
  oauthClient.requestAuthorizationCode()
}

export async function getIntegrationToken(data) {
  localStorage.removeItem('integrationLoginAttempt')
  const oauthClient = new OAuth2AuthCodePkceClient({
    scopes: data.scopes,
    authorizationUrl: data.authorizationUrl,
    clientId: data.clientId,
    redirectUrl: data.redirectUrl,
    tokenUrl: data.tokenUrl,
  })
  try {
    await oauthClient.receiveCode()
    const tokenRes = await oauthClient.getTokens({})

    localStorage.setItem(data.name, JSON.stringify(tokenRes))
    oauthClient.reset()
    if (data?.doOnboarding && tokenRes?.accessToken)
      localStorage.setItem('onBoardingData', JSON.stringify(data))
  } catch (e) {
    console.log('something went wrong: ', e)
  }
}

export async function loadIntegrationData(integrationUrls: any) {
  const availableIntegrations = get(
    store.getState(),
    'integrations.availableIntegrations',
  )
  const onBoardingData = localStorage.getItem('onBoardingData')
  const parsedOnboardingData: IntegrationOnboarding =
    onBoardingData && onBoardingData != '' ? JSON.parse(onBoardingData) : {}
  if (onBoardingData && onBoardingData != '') {
    localStorage.removeItem('onBoardingData')
    onboardIntegration(
      integrationUrls[parsedOnboardingData.id],
      parsedOnboardingData,
    )
  }
  let sanitizedUrls = {}
  if (!isEmpty(integrationUrls)) {
    for (let key in integrationUrls) {
      if (integrationUrls[key]?.authenticated) {
        sanitizedUrls[key] = integrationUrls[key]
      } else {
        const foundIntegration = availableIntegrations.find(
          (integration) => integration.id == key,
        )
        if (foundIntegration) localStorage.removeItem(foundIntegration.name)
      }
    }
  } else {
    for (let i = 0; i < availableIntegrations.length; i++) {
      const authData = localStorage.getItem(availableIntegrations[i].name)

      if (authData) {
        localStorage.removeItem(availableIntegrations[i].name)
      }
    }
  }
  store.dispatch(setIntegrationUrls(sanitizedUrls))
}

export async function onboardIntegration(
  integrationUrls: any,
  onBoardingData: IntegrationOnboarding,
) {
  if (onBoardingData?.name != null) {
    if (onBoardingData?.name == c.JD_OPERATIONS_CENTER) {
      await opsCenterOnboarding(onBoardingData, integrationUrls)
    } else if (onBoardingData?.name == 'trimble_ag') {
      await trimbleOnboarding(onBoardingData, integrationUrls)
    }
  }
}

export async function opsCenterOnboarding(
  data: IntegrationOnboarding,
  integrationUrls: any,
) {
  let linksObj = { authenticated: false }
  if (integrationUrls.authenticated) {
    linksObj.authenticated = true

    let grantAccess: boolean = true
    let grantAccessUri = ''
    for (let key in integrationUrls) {
      if (integrationUrls[key].manage_connection) {
        grantAccess = false
      } else if (grantAccessUri === '' && integrationUrls[key].connections) {
        grantAccessUri =
          integrationUrls[key].connections +
          '?redirect_uri=' +
          encodeURI(window.location.origin)
      }
    }

    if (grantAccess && grantAccessUri != '' && data?.attempt <= 1) {
      data.attempt++
      localStorage.setItem('onBoardingData', JSON.stringify(data))
      window.location.replace(grantAccessUri)
    }
  }
}

export async function trimbleOnboarding(
  data: IntegrationOnboarding,
  integrationUrls: any,
) {
  if (integrationUrls.authenticated) {
    let linksObj = { authenticated: false }
    linksObj.authenticated = true

    let grantAccess: boolean = true
    let grantAccessUri = ''
    for (let key in integrationUrls) {
      if (
        key === 'shouldRequireApproval' &&
        integrationUrls['shouldRequireApproval']
      ) {
        grantAccessUri = `${data?.approvalUrl}?clientid=${
          data?.clientId
        }&redirectionUrl=${encodeURI(window.location.origin)}`
        grantAccess = true
      } else {
        grantAccess = false
      }
    }
    if (grantAccess && grantAccessUri != '' && data?.attempt <= 1) {
      data.attempt++
      localStorage.setItem('onBoardingData', JSON.stringify(data))
      window.location.replace(grantAccessUri)
    }
  }
}

export function uploadHarvests(data: HarvestUploadData) {
  return (dispatch) => {
    http.post('tenant-integration/file-sync', data).then(() => {
      dispatch(setHarvestSyncStatus(c.DONE))
    })
  }
}

export function manageIntegrationPermissions(data) {
  const { connectionUri, token, integrationId } = data

  const availableIntegrations = get(
    store.getState(),
    'integrations.availableIntegrations',
  )
  const filteredIntegrations: IntegrationData[] = availableIntegrations.filter(
    (integration) => integration.id === integrationId,
  )
  if (filteredIntegrations.length > 0) {
    if (filteredIntegrations[0].name == c.JD_OPERATIONS_CENTER) {
      window.location.replace(
        connectionUri + '&redirect_uri=' + encodeURI(window.location.origin),
      )
    }
  }
}

export const integrationImportClients = (data) => {
  return (dispatch) => {
    data.action = c.IMPORT
    return http.post('/tenant-integration/clients', data).then((res) => {
      if (res?.data?.clients?.length > 0) {
        dispatch(setClientSyncStatus(c.DONE))
        dispatch(clientsFetched(res.data.clients))
      }
    })
  }
}

export const integrationExportClients = (data) => {
  return (dispatch) => {
    data.action = c.EXPORT
    return http.post('/tenant-integration/clients', data).then((res) => {
      if (res?.data?.clients?.length > 0) {
        dispatch(setClientSyncStatus(c.DONE))
        dispatch(clientsFetched(res.data.clients))
      }
    })
  }
}

export const integrationSyncClients = (data) => {
  return (dispatch) => {
    data.action = c.SYNC
    return http.post('/tenant-integration/clients', data).then((res) => {
      if (res?.data?.clients?.length > 0) {
        dispatch(setClientSyncStatus(c.DONE))
        dispatch(clientsFetched(res.data.clients))
      }
    })
  }
}

export const integrationSyncAll = (data) => {
  return (dispatch) => {
    data.action = c.SYNC
    return http.post('/tenant-integration/sync', data).then((res) => {
      if (res?.data?.clientErrors > 0 || res?.data?.farmErrors > 0) {
        dispatch(setAllSyncStatus(c.ERROR))
      } else {
        dispatch(setAllSyncStatus(c.DONE))
      }
      if (res?.data?.clients?.length > 0) {
        if (res.data?.clients) {
          dispatch(clientsFetched(res.data.clients))
        }
        if (res.data?.farms?.length) {
          dispatch(farmsFetched(res.data.farms))
        }
        if (res.data?.fields?.length) {
          dispatch(fieldsFetched(res.data.fields))
        }
        if (res.data?.fieldSyncJob) {
          dispatch(createJob(res.data.fieldSyncJob))
          fetchSyncingFields()
        }
        if (res.data?.boundaries?.length) {
          dispatch(updateFeaturesOverview(res.data.boundaries))
        }
        dispatch(
          setIntegrationSyncErrors(
            pick(res.data, ['clientErrors', 'farmErrors']),
          ),
        )
      }
    })
  }
}

export const integrationImportFarms = (data) => {
  return (dispatch) => {
    data.action = c.IMPORT
    return http.post('/tenant-integration/farms', data).then((res) => {
      if (res.data.farms.length > 0) {
        dispatch(setFarmSyncStatus(c.DONE))
        dispatch(farmsFetched(res.data.farms))
      }
    })
  }
}

export const integrationExportFarms = (data) => {
  return (dispatch) => {
    data.action = c.EXPORT
    return http.post('/tenant-integration/farms', data).then((res) => {
      if (res.data.farms.length > 0) {
        dispatch(setFarmSyncStatus(c.DONE))
        dispatch(farmsFetched(res.data.farms))
      }
    })
  }
}

export const integrationSyncFarms = (data) => {
  return (dispatch) => {
    data.action = c.SYNC
    return http.post('/tenant-integration/farms', data).then((res) => {
      if (res?.data?.farms?.length > 0) {
        dispatch(setFarmSyncStatus(c.DONE))
        dispatch(farmsFetched(res.data.farms))
      }
    })
  }
}

export const integrationSyncFields = (data) => {
  return (dispatch) => {
    data.action = c.SYNC
    return http.post('/tenant-integration/fields', data).then((res) => {
      if (data?.jobId) {
        const status = res?.data?.fieldSyncJob?.status
        if (status === c.DONE) {
          dispatch(updateJob(res?.data?.fieldSyncJob))
        }
      } else {
        dispatch(createJob(res?.data?.fieldSyncJob))
        fetchSyncingFields()
      }
      dispatch(setFieldSyncStatus(c.DONE))
      if (res?.data?.fields?.length > 0) {
        dispatch(fieldsFetched(res.data.fields))
      }
      if (res.data.boundaries.length > 0) {
        dispatch(updateFeaturesOverview(res.data.boundaries))
      }
      dispatch(
        setIntegrationSyncErrors(
          pick(res.data, ['fieldErrors', 'boundaryErrors']),
        ),
      )
    })
  }
}

export const linkIntegration = (data: IntegrationLinkRequest) => {
  const { integrationId, customerId, name } = data
  const requestData = {
    integrationId: integrationId,
    customerId: customerId,
    name: name,
  }
  return (dispatch) => {
    return http.post('/tenant-integration', requestData).then((res) => {
      dispatch(integrationCreated(res.data))
    })
  }
}

export const fetchSyncingFields = () => {
  function getFieldsSynced() {
    const jobs = get(store.getState(), 'job.collection')
    const pendingFieldSync = jobs.filter(
      (job) =>
        job.jobType === c.FIELD_SYNC_JOB_TYPE && job.status === c.STARTED,
    )
    if (pendingFieldSync.length > 0) {
      //we really should have at most one in this state
      if (pendingFieldSync.length > 1) {
        pendingFieldSync.sort((a, b) => {
          const aDate = new Date(a.createdAt)
          const bDate = new Date(b.createdAt)
          if (aDate > bDate) return 1
          else if (aDate < bDate) return -1
          return 0
        })
      }

      const data = {
        action: c.SYNC,
        jobId: pendingFieldSync[0].id,
      }
      http.post('/tenant-integration/fields', data).then((res) => {
        const status = res?.data?.fieldSyncJob?.status
        if (status === c.DONE) {
          store.dispatch(updateJob(res?.data?.fieldSyncJob))

          store.dispatch(setFieldSyncStatus(c.DONE))
          if (res?.data?.fields?.length > 0) {
            store.dispatch(fieldsFetched(res.data.fields))
          }
          if (res.data.boundaries.length > 0) {
            store.dispatch(updateFeaturesOverview(res.data.boundaries))
          }
        } else {
          setTimeout(getFieldsSynced, 30000)
        }
      })
    }
  }

  getFieldsSynced()
}
