import { get, findIndex, cloneDeep } from 'lodash-es'
import * as c from 'common/c'

const initialState = {
  collection: [],
  fieldsHarvested: [],
  singleton: undefined,
  features: undefined,
  features_a: undefined,
  features_b: undefined,
  featuresOverview: [],
  defaultExtent: undefined,
  currentFeatures: new Map(),
  layers: {},
  layers_a: {},
  layers_b: {},
  layerInfo: undefined,
  layerInfo_a: undefined,
  layerInfo_b: undefined,
  fieldsImported: undefined,
  fieldsHarvested_a: [],
  fieldsHarvested_b: [],
  field_a: undefined,
  field_b: undefined,
  crop: 'all',
  year: undefined,
  year_a: undefined,
  year_b: undefined,
  editFieldName: false,
}

function compareFieldName(a, b) {
  const lang = navigator.language || navigator.userLanguage
  //This should sort everything correctly (or mostly correctly) based on
  //users language might have still have issues if names are in two different
  //languages (then again I'm not sure if there are any good ways to handle that)
  const aName = a?.name?.toLocaleLowerCase(lang)
  const bName = b?.name?.toLocaleLowerCase(lang)
  // Sort things in numeric order, ie field 1, field 2, field 11
  // instead of field 1, field 11, 2.
  return aName.localeCompare(bName, lang, { numeric: true })
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.FIELDS_FETCHED: {
      action.payload.sort((a, b) => {
        return compareFieldName(a, b)
      })
      return update(state, { collection: { $set: action.payload } })
    }

    case c.HARVESTED_FIELDS_FETCHED: {
      action.payload.sort((a, b) => {
        return compareFieldName(a, b)
      })
      return update(state, { fieldsHarvested: { $set: action.payload } })
    }

    case c.SET_DEFAULT_EXTENT: {
      return update(state, { defaultExtent: { $set: action.payload } })
    }

    case c.SET_FEATURES_OVERVIEW: {
      return update(state, { featuresOverview: { $set: action.payload } })
    }

    case c.UPDATE_FEATURES_OVERVIEW: {
      let featuresOverview = cloneDeep(state.featuresOverview)
      let fieldList = cloneDeep(state.collection)

      for (let i = 0; i < action.payload.length; i++) {
        const { fieldId, geometry, properties, boundarySvg } = action.payload[i]
        const fieldIndex = fieldList.findIndex((p) => p.id === fieldId)
        const index = featuresOverview.findIndex((p) => p.id === fieldId)
        if (index > -1) {
          featuresOverview[index] = { id: fieldId, geometry, properties }
        } else {
          featuresOverview.push({ id: fieldId, geometry, properties })
        }

        if (fieldIndex > -1) {
          const field = fieldList[fieldIndex]
          fieldList[fieldIndex] = { ...field, ...{ boundarySvg: boundarySvg } }
        }
      }
      return update(state, {
        featuresOverview: { $set: featuresOverview },
        collection: { $set: fieldList },
      })
    }

    case c.UPDATE_COUNTRY_EXTENT: {
      return update(state, { defaultExtent: { $set: action.payload } })
    }

    case c.EDIT_FIELD: {
      const { id } = action.payload

      const index = state.collection.findIndex((p) => p.id === id)

      const oToUpdate = { singleton: { $set: action.payload } }

      if (index > -1) {
        oToUpdate.collection = { [index]: { $set: action.payload } }
      }

      return update(state, oToUpdate)
    }

    case c.DELETE_FIELD: {
      const oToUpdate = {
        features: { $set: [] },
        currentFeatures: { $set: new Map() },
        singleton: { $set: undefined },
      }

      const idx = findIndex(
        get(state, 'collection', []),
        (field) => field.id === action.payload,
      )
      if (idx > -1) {
        oToUpdate.collection = { $splice: [[idx, 1]] }
      }

      const featureIdx = findIndex(
        get(state, 'featuresOverview', []),
        (field) => field.id === action.payload,
      )
      if (featureIdx > -1) {
        oToUpdate.featuresOverview = { $splice: [[featureIdx, 1]] }
      }

      return update(state, oToUpdate)
    }

    case c.FIELD_FETCHED: {
      return update(state, { singleton: { $set: action.payload } })
    }

    case c.FIELD_FETCHED_A: {
      return update(state, { field_a: { $set: action.payload } })
    }

    case c.FIELD_FETCHED_B: {
      return update(state, { field_b: { $set: action.payload } })
    }

    case c.ADD_FIELD_FEATURE: {
      const feature = action.payload
      return update(state, {
        currentFeatures: { $add: [[feature.id, feature]] },
      })
    }

    case c.SET_FIELD_FEATURES: {
      return update(state, { currentFeatures: { $set: action.payload } })
    }

    case c.CLEAR_FIELD_FEATURES: {
      return update(state, {
        features: { $set: [] },
        currentFeatures: { $set: new Map() },
      })
    }

    case c.FEATURES_FETCHED: {
      return update(state, { features: { $set: action.payload } })
    }

    case c.FEATURES_A_FETCHED: {
      return update(state, { features_a: { $set: action.payload } })
    }

    case c.FEATURES_B_FETCHED: {
      return update(state, { features_b: { $set: action.payload } })
    }

    case c.SET_LAYERS: {
      return update(state, { layers: { $merge: action.payload } })
    }

    case c.SET_CROP: {
      return update(state, { crop: { $set: action.payload.data } })
    }

    case c.SET_YEAR: {
      return update(state, { year: { $set: action.payload.data } })
    }

    case c.SET_YEAR_A: {
      return update(state, { year_a: { $set: action.payload.data } })
    }

    case c.SET_YEAR_B: {
      return update(state, { year_b: { $set: action.payload.data } })
    }

    case c.SET_LAYERS_A: {
      return update(state, { layers_a: { $merge: action.payload } })
    }

    case c.SET_LAYERS_B: {
      return update(state, { layers_b: { $merge: action.payload } })
    }

    case c.RESET_LAYERS: {
      return update(state, {
        layers: {
          $set: {},
        },
      })
    }

    case c.RESET_LAYERS_A: {
      return update(state, {
        layers_a: {
          $set: {},
        },
      })
    }

    case c.RESET_LAYERS_B: {
      return update(state, {
        layers_b: {
          $set: {},
        },
      })
    }

    case c.LAYER_INFO_FETCHED: {
      return update(state, { layerInfo: { $set: action.payload } })
    }

    case c.LAYER_INFO_A_FETCHED: {
      return update(state, { layerInfo_a: { $set: action.payload } })
    }

    case c.LAYER_INFO_B_FETCHED: {
      return update(state, { layerInfo_b: { $set: action.payload } })
    }

    case c.FIELDS_HARVESTED_A_FETCHED: {
      action.payload.sort((a, b) => {
        return compareFieldName(a, b)
      })
      return update(state, { fieldsHarvested_a: { $set: action.payload } })
    }

    case c.FIELDS_HARVESTED_B_FETCHED: {
      action.payload.sort((a, b) => {
        return compareFieldName(a, b)
      })
      return update(state, { fieldsHarvested_b: { $set: action.payload } })
    }

    case c.FIELDS_IMPORTED: {
      return update(state, { fieldsImported: { $set: action.payload } })
    }

    case c.DELETE_FIELDS: {
      let ids = action.payload
      let final = []
      let finalOverview = []

      const oToUpdate = {}

      for (var i = 0; i < ids.length; i++) {
        final =
          final.length > 0
            ? final.filter((p) => p.id != ids[i])
            : state.collection.filter((p) => p.id != ids[i])
        finalOverview =
          finalOverview.length > 0
            ? finalOverview.filter((p) => p.id != ids[i])
            : state.featuresOverview.filter((p) => p.id != ids[i])
      }
      oToUpdate.collection = final
      oToUpdate.featuresOverview = finalOverview
      return update(state, oToUpdate)
    }

    case c.SELECT_FIELD_A: {
      return update(state, { field_a: { $set: action.payload } })
    }

    case c.SELECT_FIELD_B: {
      return update(state, { field_b: { $set: action.payload } })
    }

    case c.EDIT_FIELD_NAME: {
      return update(state, { editFieldName: { $set: action.payload } })
    }

    default:
      return state
  }
}
