import { get, findIndex, cloneDeep } from 'lodash-es'
import update from 'immutability-helper'
import * as c from 'common/c'

declare module 'state/harvest' {
  export type HarvestModel = {
    crop: any
    cropId: number
    fieldId: number
    geom: any
    id: number
    stats: any
    status: string
    tenantId: string
    testWeightKgHl: number
    totalYield: number
    totalYieldUnit: string
    startDate: string
    endDate: string
    cropYear: number
    ymIDHeaders: YMIdHeader[]
  }
  export type YMIdHeader = {
    ymHeader: {
      headerWidthMeters: number
      numberPoints: number
      percentage: number
      ymID: string
    }
  }
  export type HarvestYearModel = {
    harvestYr: number
    hasHarvest: boolean
    fieldIds?: number[]
  }
  export type LayerDownloadUrl = {
    url?: string
    fileName?: string
  }

  export type harvest = {
    current: HarvestModel | undefined
    current_a: HarvestModel | undefined
    current_b: HarvestModel | undefined
    collection: HarvestModel[]
    collection_a: HarvestModel[]
    collection_b: HarvestModel[]
    features: Map<any, any>
    years: HarvestYearModel[]
    byYear: HarvestModel[]
    layerDownloadUrl: LayerDownloadUrl | null
  }
}

const initialState = {
  current: undefined,
  current_a: undefined,
  current_b: undefined,
  collection: [],
  collection_a: [],
  collection_b: [],
  features: new Map(),
  years: [],
  byYear: [],
  layerDownloadUrl: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.SINGLE_HARVEST_FETCHED: {
      return update(state, { current: { $set: action.payload } })
    }

    case c.SINGLE_HARVEST_A_FETCHED: {
      return update(state, { current_a: { $set: action.payload } })
    }

    case c.SINGLE_HARVEST_B_FETCHED: {
      return update(state, { current_b: { $set: action.payload } })
    }

    case c.HARVESTS_FETCHED: {
      //by field, year and crop
      return update(state, { collection: { $set: action.payload } })
    }

    case c.UPDATE_HARVEST_BY_ID:
      {
        const { request, response } = action.payload

        const { id } = response
        response.crop = request?.crop
        const index = state.collection.findIndex((p: any) => p.id === id)
        if (index > -1) {
          return update(state, {
            //@ts-ignore
            collection: { $splice: [[index, 1, response]] },
          })
        }
      }
      break

    case c.UPDATE_HARVEST_HEADER:
      {
        const { id, data } = action.payload
        const index = state.collection.findIndex((p: any) => p.id === id)
        if (index > -1) {
          //@ts-ignore
          return update(state, { current: { $merge: { headerInfo: data } } })
        }
      }
      break

    case c.HARVEST_HEADERS_FETCHED:
      {
        const index = state.collection.findIndex(
          (p: any) => p.id == action.payload.id,
        )
        if (index > -1) {
          return update(state, {
            //@ts-ignore
            current: { $merge: { ymIDHeaders: action.payload.data } },
          })
        }
      }
      break

    case c.SET_HARVEST_FEATURES: {
      const fetchedFeatures = action.payload
      const featuresById = new Map()

      for (let f of fetchedFeatures) {
        featuresById.set(f.id, f)
      }

      return update(state, { features: { $set: featuresById } })
    }

    case c.ADD_HARVEST_FEATURE: {
      const feature = action.payload
      return update(state, {
        features: { $add: [[feature.id, feature]] },
      })
    }

    case c.HARVEST_YEARS_FETCHED: {
      return update(state, { years: { $set: action.payload.data } })
    }

    case c.HARVESTS_BY_YEAR_FETCHED: {
      return update(state, { byYear: { $set: action.payload.data } })
    }

    case c.HARVESTS_A_FETCHED: {
      return update(state, { collection_a: { $set: action.payload } })
    }

    case c.HARVESTS_B_FETCHED: {
      return update(state, { collection_b: { $set: action.payload } })
    }

    case c.DOWNLOADING_LAYER: {
      return update(state, { layerDownloadUrl: { $set: action.payload } })
    }
    default:
      return state
  }
}
