import { DataGrid } from '@mui/x-data-grid'
import { makeStyles } from '@mui/styles'
import { Box, IconButton } from '@mui/material'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import PersonOffIcon from '@mui/icons-material/PersonOff'
import PersonIcon from '@mui/icons-material/Person'
import SearchField from '../search-field'
import ChangeAccountStatus from 'components/account-change-modal'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import colors from 'common/colors'
import { showModal } from 'actions/app'

const useStyles = makeStyles(() => ({
  dataGridContainer: {
    flexGrow: 1,
    height: '100%',
    width: '100%',
    marginTop: '2rem',
  },
  dataGridHeader: {
    background: '#f8f8f9',
    textTransform: 'uppercase',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    height: '90%',
  },
  searchField: {
    width: '100%',
  },
}))

export default function Organizations(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { organizations, onClick } = props
  const [hoveredRow, setHoveredRow] = useState('')
  const permissions = useSelector((state) => state.account.permissions)

  const [columns, setColumns] = useState([])
  const isAdmin = permissions.includes('farmtrx:admin')
  const isPartner = permissions.includes('partner:admin')
  const [columnVisibility, setColumnVisibility] = useState({
    account_enabled: true,
    tenantId: isAdmin,
    organizationName: true,
    fullName: true,
    email: true,
    phoneNumber: true,
    partnerName: isAdmin,
  })

  useEffect(() => {
    const _columns = [
      {
        field: 'account_enabled',
        headerName: t('status'),
        hideSortIcons: true,
        disableColumnMenu: true,
        minWidth: 70,
        maxWidth: 75,
        renderCell: (params) => {
          const changeStatus = (e) => {
            if (isPartner || isAdmin) {
              e.stopPropagation()
              dispatch(showModal(<ChangeAccountStatus data={params.row} />))
            }
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                width: 60,
              }}
            >
              <IconButton
                title={
                  params.row?.accountEnabled ? t('deactivate') : t('activate')
                }
                onClick={changeStatus}
                size="small"
              >
                {params.row?.accountEnabled ? (
                  <PersonIcon style={{ color: colors.brandLight }} />
                ) : (
                  <PersonOffIcon style={{ color: '#B2023B' }} />
                )}
              </IconButton>
            </div>
          )
        },
      },
      {
        field: 'tenantId',
        headerName: t('tenant_id'),
        minWidth: 200,
        maxWidth: 310,
        flex: 1,
        renderCell: (params) => {
          const handleCopy = (e) => {
            e.stopPropagation()
            async function copy() {
              try {
                await navigator.clipboard.write([
                  new window.ClipboardItem({
                    'text/plain': new Blob([params.row.tenantId], {
                      type: 'text/plain',
                    }),
                  }),
                ])
              } catch (e) {
                console.log(e)
              }
            }
            copy()
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              {params.row.id === hoveredRow ? (
                <IconButton title={t('copy')} onClick={handleCopy}>
                  <ContentPasteIcon />
                </IconButton>
              ) : null}
              {params.row.tenantId}
            </div>
          )
        },
      },
      {
        field: 'organizationName',
        headerName: t('organization_name'),
        minWidth: 150,
        maxWidth: 300,
        flex: 1,
        headerClassName: 'dataGridHeader',
        renderCell: (params) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left',
                height: '100%',
              }}
            >
              {params.row.organizationName}
            </div>
          )
        },
      },
      {
        field: 'fullName',
        headerName: t('full_name'),
        minWidth: 200,
        maxWidth: 400,
        align: 'left',
        flex: 1,
      },
      {
        field: 'email',
        headerName: t('email'),
        minWidth: 200,
        maxWidth: 400,
        align: 'left',
        flex: 1,
        renderCell: (params) => {
          const handleCopy = (e) => {
            e.stopPropagation()
            async function copy() {
              try {
                await navigator.clipboard.write([
                  new window.ClipboardItem({
                    'text/plain': new Blob([params.row.email], {
                      type: 'text/plain',
                    }),
                  }),
                ])
              } catch (e) {
                console.log(e)
              }
            }
            copy()
          }
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'left',
                justifyContent: 'left',
                height: '100%',
              }}
            >
              {params.row.id === hoveredRow ? (
                <IconButton title={t('copy')} onClick={handleCopy}>
                  <ContentPasteIcon />
                </IconButton>
              ) : null}
              {params.row.email}
            </div>
          )
        },
      },
      {
        field: 'phoneNumber',
        headerName: t('phone_number'),
        minWidth: 150,
        maxWidth: 200,
        align: 'left',
        flex: 1,
      },
      {
        field: 'partnerName',
        headerName: t('partner_name'),
        minWidth: 150,
        maxWidth: 200,
        align: 'left',
        flex: 1,
      },
    ]
    setColumns(_columns)
  }, [permissions, hoveredRow])

  const onSearch = (value) => {
    setSearchValue(value)
  }

  const onMouseEnterRow = (event) => {
    setHoveredRow(event.currentTarget.dataset.id)
  }
  const onMouseLeaveRow = () => {
    setHoveredRow('')
  }

  const [searchValue, setSearchValue] = useState('')

  const filterOrganizations = (searchValue, organization) => {
    if (searchValue) {
      const lowerSearchValue = searchValue.toLowerCase()

      return (
        organization.tenantId.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        organization.organizationName?.toLowerCase().indexOf(lowerSearchValue) >
          -1 ||
        organization.fullName?.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        organization.email?.toLowerCase().indexOf(lowerSearchValue) > -1 ||
        organization.phoneNumber?.toLowerCase().indexOf(lowerSearchValue) > -1
      )
    }

    return true
  }

  function CustomNoRowsOverlayOrganizations() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {t('no_organizations')}
      </div>
    )
  }

  return (
    <Box className={classes.pageContainer} width={1}>
      <h1>{t('available_organizations')}</h1>
      <SearchField
        className={classes.searchField}
        name={searchValue}
        placeholder={t('search_by_org_name_email')}
        onSearch={onSearch}
      />
      <div className={classes.dataGridContainer}>
        <DataGrid
          autoPageSize
          columnVisibilityModel={columnVisibility}
          onColumnVisibilityModelChange={(model) => setColumnVisibility(model)}
          initialState={{
            sorting: {
              sortModel: [{ field: 'organizationName', sort: 'asc' }],
            },
          }}
          slots={{
            noRowsOverlay: CustomNoRowsOverlayOrganizations,
          }}
          slotProps={{
            row: {
              onMouseEnter: onMouseEnterRow,
              onMouseLeave: onMouseLeaveRow,
            },
          }}
          rows={
            Array.isArray(organizations) &&
            organizations
              .map((c) => ({
                ...c,
                id: c.tenantId,
                tenantId: c.tenantId,
                organizationName: c.organizationName,
              }))
              .filter((c) => filterOrganizations(searchValue, c))
          }
          columns={columns}
          classes={{ columnHeader: classes.dataGridHeader }}
          pageSizeOptions={[10]}
          onRowClick={(params) => onClick(params.row)}
        />
      </div>
    </Box>
  )
}
