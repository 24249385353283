import styled from 'styled-components'
import { changeTestWeight } from 'api/harvest'
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { closeModal } from 'actions/app'
import { useSelector } from 'react-redux'
import withStyles from '@mui/styles/withStyles'
import ModalTool from './modal/modal-tool'

export default function ChangeTestWeight() {
  const testWeightUnit = useSelector(
    (state) => state.account.singleton.prefs.units.testweight_in,
  )
  const testWeightList = Object.keys(
    useSelector((state) => state.account.options.units.testWeight),
  )

  const [val, setVal] = useState()
  const [type, setType] = useState(testWeightUnit ?? testWeightList[0])

  const id = useSelector((state) => state.harvest.current?.id)

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const onValueChange = useCallback((e) => {
    setVal(e.target.value)
  })

  const onSubmit = useCallback(() => {
    if (val != 0) {
      let value = val
      // convert value
      if (type == 'lb_usbu') {
        value = val * 1.25
      } else if (type == 'lb_abu') {
        value = val * 1.247
        // } else if(type == 'lbwbu') {
        //   value = val * 0.1552
      } else if (type == 'bu_tonne') {
        value = val * 2837.759
      }

      dispatch(changeTestWeight(id, value))
    }
  })

  const onUnitChange = useCallback((e) => {
    setType(e.target.value)
  })

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })

  return (
    <ModalTool
      header={t('change_tw')}
      content={
        <StyledForm>
          <StyledInputField
            control={
              <StyledTextField
                id="testWeight"
                name="testWeight"
                variant="outlined"
                size="small"
                onChange={onValueChange}
                defaultValue={val}
              />
            }
            label={t('test_weight')}
            labelPlacement="start"
          />
          <StyledFormControl>
            <Select onChange={onUnitChange} value={type}>
              {testWeightList.map((tw) => (
                <MenuItem key={tw} value={tw}>
                  {t(tw)}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </StyledForm>
      }
      onClose={closePopup}
      onApply={onSubmit}
    />
  )
}

const StyledForm = styled.div`
  display: flex;
  justify-content: end;

  & > *:not(:last-child) {
    margin-right: 0.5em;
  }
`

const StyledInputField = styled(FormControlLabel)`
  flex-grow: 1;
`

const StyledTextField = withStyles({
  root: {
    width: '25ch',
    padding: '10px',
  },
})(TextField)

const StyledFormControl = withStyles({
  root: {
    padding: '10px',
  },
})(FormControl)
