import FieldExplorer from 'components/field-explorer'
import FieldForm from 'components/field-form'
import CropYearPick from 'components/crop-year-pick'
import { Header as SideMenuHeader } from 'components/side-menu'
import SearchField from 'components/search-field'
import { fieldFetched, setCrop, setYear } from 'actions/field'
import { assignSelectedClient } from 'actions/client'
import { assignSelectedFarms } from 'actions/farm'
import { showModal } from 'actions/app'
import { isNil, find, get, isEmpty } from 'lodash-es'
import { fetchFilteredFields } from 'api/field'
import { reprocessHarvest, findHarvestsByFieldYearCrop } from 'api/harvest'
import { harvestFetched } from 'actions/harvest'
import SetHeader from 'components/set-header'
import OverrideCropType from 'components/override-crop-type'
import ChangeTare from 'components/set-tare'
import EditCropCalibration from 'components/edit-crop-calibration'
import ChangeTotalYield from 'components/change-total-yield'
import ChangeTestWeight from 'components/change-test-weight'
import ChangeTimeDelay from 'components/change-time-delay'
import ChangeHarvestYear from 'components/change-harvest-year'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useCallback, useEffect } from 'react'
import useDebounce from 'hooks/util/debounce'
import { getAppBarHeight } from 'common/misc'

import { closeModal } from 'actions/app'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 0 0 0',
    '& > div': {
      padding: '0 11px',
    },
  },
  searchField: {
    width: '100%',
    height: '30px',
  },
})

export default function SidePanel() {
  const classes = useStyles()
  const dispatch = useDispatch()

  const fields = useSelector((state) => get(state, 'field.collection', []))
  const harvestedFields = useSelector((state) =>
    get(state, 'field.fieldsHarvested'),
  )
  const crops = useSelector((state) => state.crop['cropsWithCropVarieties'])
  const field = useSelector((state) => get(state, 'field.singleton'))
  const fieldFeatures = useSelector((state) => get(state, 'field.features'))
  const harvests = useSelector((state) => state.harvest.collection)
  const initialCrop = useSelector((state) => get(state, 'field.crop'))
  const initialYear = useSelector((state) => get(state, 'field.year'))
  const appBarHeight = getAppBarHeight()

  const selectedClient = useSelector((state) => get(state, 'client.singleton'))
  const farms = useSelector((state) => get(state, 'farm.selectedFarms'))
  const [crop, setSelectedCrop] = useState(initialCrop)
  const [year, setSelectedYear] = useState(initialYear)

  const [hideEmptyFields, setHideEmptyFields] = useState(false)
  const [filteredFields, setFields] = useState(fields)
  const [filteredHarvestedFields, setFilteredHarvestedFields] =
    useState(harvestedFields)
  const [filterString, setFilterString] = useState('')
  const harvestYears = useSelector((state) => state.harvest.years)
  const [height, setHeight] = useState(window.innerHeight)

  const cffEnabled = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )

  useEffect(() => {
    if (appBarHeight) {
      // Resize the component
      setHeight(window.innerHeight - appBarHeight)
    }
  }, [appBarHeight])

  useEffect(() => {
    setHideEmptyFields(crop !== 'all')
  }, [crop])

  const onCreateField = useCallback(() => {
    dispatch(showModal(<FieldForm />))
  }, [])

  const findFieldById = useCallback(
    (id) => {
      dispatch(fieldFetched(find(fields, (o) => o.id === id)))
      if (year) {
        dispatch(findHarvestsByFieldYearCrop(id, year, crop))
      }
      dispatch(harvestFetched({}))
    },
    [fields, year, crop],
  )

  const setSelectedClient = useCallback((client) => {
    dispatch(assignSelectedClient(client))
  }, [])

  const setSelectedFarms = useCallback(
    (farms) => {
      if (year) {
        if (farms.length) {
          dispatch(assignSelectedFarms(farms))
        } else {
          dispatch(assignSelectedFarms([]))
        }
      }
    },
    [year, crop],
  )

  // const onEditField = useCallback(() => {
  //   dispatch(startEditField())
  // }, [])

  const onSeasonChange = useCallback(
    (season) => {
      const [_year, _crop] = season.split('_')
      if (_year !== year && _year !== undefined && _year !== '') {
        dispatch(setYear(parseInt(_year)))
        setSelectedYear(_year)
      }
      if (_crop && crop != 'undefined') {
        setSelectedCrop(_crop)
        dispatch(setCrop(_crop))
      }
    },
    [year, crop],
  )

  useEffect(() => {
    if (year && crop) {
      if (cffEnabled) {
        if (farms?.length) {
          let farmIds = farms?.reduce((result, a) => {
            if (!isNil(a?.id)) {
              result.push(a.id)
            }
            return result
          }, [])
          dispatch(fetchFilteredFields(year, crop, selectedClient?.id, farmIds))
        } else if (selectedClient) {
          dispatch(fetchFilteredFields(year, crop, selectedClient?.id))
        } else {
          dispatch(fetchFilteredFields(year, crop))
        }
      } else {
        dispatch(fetchFilteredFields(year, crop))
      }
    }
  }, [year, crop, selectedClient, farms, cffEnabled])

  useEffect(() => {
    if (fields) {
      setFields(fields)
    }
  }, [fields])

  const onChangeTY = useCallback(() => {
    dispatch(showModal(<ChangeTotalYield />))
  }, [])

  const changeTestWeight = useCallback(() => {
    dispatch(showModal(<ChangeTestWeight />))
  }, [])

  const onSetHeader = useCallback(() => {
    dispatch(showModal(<SetHeader />))
  }, [])

  const onEditSensorCal = useCallback(() => {
    // setUpdateTare(true)
    dispatch(showModal(<ChangeTare />))
  }, [])

  const onChangeCropCal = useCallback(() => {
    // setChangeCropCal(true)
    dispatch(showModal(<EditCropCalibration />))
  })

  const onChangeTimeDelay = useCallback(() => {
    dispatch(showModal(<ChangeTimeDelay />))
  })

  const onChangeHarvestYear = useCallback(() => {
    dispatch(showModal(<ChangeHarvestYear />))
  })

  const onCropTypeOverride = useCallback(() => {
    dispatch(showModal(<OverrideCropType crops={crops} />))
  }, [crops])

  const invokeDebouncedSearch = useDebounce(() => {
    if (isEmpty(filterString) && fields.length > 0) {
      setFields(fields)
      setFilteredHarvestedFields(harvestedFields)
    } else {
      const filter = filterString.toLowerCase()
      setFields(fields.filter((f) => f.name.toLowerCase().includes(filter)))
      setFilteredHarvestedFields(
        harvestedFields.filter((f) => f.name.toLowerCase().includes(filter)),
      )
    }
  }, 250)

  useEffect(invokeDebouncedSearch, [harvestedFields, filterString])

  const onSearchField = useCallback((name) => {
    setFilterString(name)
  })

  const onRefreshHarvest = useCallback((harvest) => {
    dispatch(reprocessHarvest(harvest.id, harvest.tenantId))
    dispatch(closeModal())
  })

  useEffect(() => {
    if (field) dispatch(findHarvestsByFieldYearCrop(field.id, year, crop))
  }, [field, year, crop])

  const hasBoundary = useMemo(
    () => fieldFeatures !== undefined && !isEmpty(fieldFeatures),
    [fieldFeatures],
  )

  return (
    <Box className={classes.container} style={{ height: `${height}px` }}>
      <SideMenuHeader onCreateField={onCreateField} />
      <SearchField
        onSearch={onSearchField}
        name={filterString}
        className={classes.searchField}
      />
      <CropYearPick
        onSeasonChange={onSeasonChange}
        crops={crops}
        defaultCrop={crop}
        defaultYear={year}
        harvestYears={harvestYears}
        assignSelectedClient={setSelectedClient}
        assignSelectedFarms={setSelectedFarms}
        selectedFarms={farms}
        selectedClient={selectedClient}
      />
      <FieldExplorer
        fields={filteredFields}
        harvestedFields={filteredHarvestedFields}
        hideEmptyFields={hideEmptyFields}
        selectedField={field}
        harvests={harvests}
        onFieldSelected={findFieldById}
        hasBoundary={hasBoundary}
        onSetHeader={onSetHeader}
        onChangeTY={onChangeTY}
        onChangeTestWeight={changeTestWeight}
        onEditSensorCal={onEditSensorCal}
        onUpdateCropCal={onChangeCropCal}
        onChangeTimeDelay={onChangeTimeDelay}
        onCropTypeOverride={onCropTypeOverride}
        onRefreshHarvest={onRefreshHarvest}
        onChangeHarvestYear={onChangeHarvestYear}
      />
    </Box>
  )
}
