import { FormControlLabel, TextField } from '@mui/material'
import { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { closeModal } from 'actions/app'
import { filter, sortBy } from 'lodash-es'

import { editHarvest } from 'api/harvest'
import styled from 'styled-components'
import { Trans } from 'react-i18next'
import { getTranslatedName } from 'common/misc'
import ModalTool from 'components/modal/modal-tool'

export default function OverrideCropType(props) {
  const { crops } = props

  const { t } = useTranslation()

  const harvest = useSelector((state) => state.harvest.current)

  const dispatch = useDispatch()

  const [cropTypes, setCropTypes] = useState(crops)

  const [inputs, setInputs] = useState()

  useEffect(() => {
    if (crops && harvest) {
      const filteredCrops = sortBy(
        filter(crops, function (variety) {
          return !(variety.name === harvest.crop.name)
        }),
        ['name'],
      )
      setInputs({
        harvestCropSelection: filteredCrops[0]?.id,
      })
      setCropTypes(filteredCrops)
    }
  }, [crops, harvest])

  const navigateToFarmSetup = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const changeCropType = useCallback(() => {
    let id = inputs?.harvestCropSelection
    let selectedCrop = filter(crops, function (crop) {
      return crop.id == id
    })
    harvest.cropId = selectedCrop[0].id
    harvest.crop = selectedCrop[0]
    dispatch(editHarvest(harvest.id, harvest))
    dispatch(closeModal())
  })

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  const disableButton = useCallback(() => {
    return inputs?.harvestCropSelection == null
  }, [inputs])

  return (
    <ModalTool
      header={t('edit_crop_type')}
      content={
        <div>
          <StyledP>
            <Trans i18nKey="update_crop_type_warning">
              <NavLink to="/farmSetup" onClick={navigateToFarmSetup}></NavLink>
            </Trans>
          </StyledP>
          <StyledForm>
            <FormControlLabel
              control={
                <TextField
                  id="harvest-crop-selection"
                  inputProps={{ disabled: true }}
                  name="originalHarvestCropSelection"
                  variant="outlined"
                  size="small"
                  value={getTranslatedName(harvest.crop.name)}
                  placeholder="Selected Harvest Crop"
                />
              }
              label={t('harvest_crop_selection')}
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <TextField
                  id="harvest-crop-selection"
                  select
                  name="harvestCropSelection"
                  variant="outlined"
                  size="small"
                  SelectProps={{ native: true }}
                  placeholder={t('select_new_crop')}
                  value={inputs?.harvestCropSelection}
                  onChange={handleInputChange}
                >
                  {cropTypes && cropTypes.length ? (
                    cropTypes.map((item) => (
                      <option value={item.id} key={item.id}>
                        {getTranslatedName(item['name'])}
                      </option>
                    ))
                  ) : (
                    <>
                      <option disabled>{t('create_new_crop')}</option>
                    </>
                  )}
                </TextField>
              }
              label={t('select_new_crop')}
              labelPlacement="start"
            />
          </StyledForm>
        </div>
      }
      onClose={closePopup}
      onApply={changeCropType}
      disabled={disableButton()}
    />
  )
}

const StyledP = styled.p`
  color: #858e97;
  font-size: 13px;
  font-weight: 500;
`

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
`
