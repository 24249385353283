import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { getDeviceString } from 'common/misc'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { get, isEmpty } from 'lodash-es'
import React, { useEffect, useState, ReactElement } from 'react'

import * as c from 'common/c'
import type { AppState } from 'state'

const useStyles = makeStyles(() => ({
  borderedBox: {
    border: '1px solid #003057',
    borderRadius: '2px',
  },
  borderBottomCustom: { borderBottom: 'none' },
  customFontSize: { fontSize: '1rem' },
}))

export default function HardwareComponent(): ReactElement<any, any> {
  const classes = useStyles()
  const { t } = useTranslation()
  const customClaims = useSelector(
    (state: AppState) => state.account.customClaims,
  )
  const permissions = useSelector(
    (state) => (state as AppState).account.permissions,
  )
  const isAdmin =
    permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM]

  let hardware = useSelector((state) =>
    get(state as AppState, 'hardware.collection' || []),
  )

  return (
    <>
      {/* <Box width={1} > */}
      <Box px={3} py={4} width={1} style={{ overflowX: 'auto' }}>
        <Box my={1} className={classes.borderedBox}>
          <TableContainer>
            <Table aria-label="FarmTRX Hardware table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('hardware_id')}</TableCell>
                  <TableCell align="left">{t('hardware_type')} </TableCell>
                  <TableCell align="left">{t('hardware_name')}</TableCell>
                  {isAdmin ? (
                    <TableCell align="left">{t('hardware_version')} </TableCell>
                  ) : null}
                  <TableCell align="left">{t('firmware_version')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hardware && hardware.length ? (
                  hardware &&
                  hardware.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {row.farmtrxHardwareId}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {t(row.hardwareType.toLowerCase())}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {getDeviceString(row.deviceType)}
                      </TableCell>
                      {isAdmin ? (
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.borderBottomCustom}
                        >
                          {row.hardwareVersion}
                        </TableCell>
                      ) : null}
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.borderBottomCustom}
                      >
                        {Array.isArray(
                          (row as any)?.metadata?.firmware_version,
                        ) && (row as any)?.metadata?.firmware_version.length > 0
                          ? (row as any).metadata.firmware_version[
                              (row as any)?.metadata?.firmware_version.length -
                                1
                            ].version
                          : t('unknown')}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell>
                      <Box width={1} justifyContent="center">
                        {t('hw_populate_cloud_upload_msg')}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  )
}
