import { get, isNil, find, isEmpty, cloneDeep, findIndex } from 'lodash-es'
import { SidePanel, PageContent } from 'components/page'
import ErrorBoundary from 'containers/app/error-boundary'
import SidePanelFields from 'containers/side-panel'
import { BingMaps, GoogleMaps, FarmtrxMap, FocusField } from 'components/map'
import FieldInfo from 'components/field-info'
import {
  defaultStyle,
  modifyStyles,
  colorList,
  selectStyle,
} from 'common/ol/styles'
import AreaInfoView from 'components/area-info-view'
import MapSourceBoundaryProvider from 'components/map-source-boundary-provider'
import MapWMSLayer from 'components/map-wms-layer'
import MapBoundaryLayer from 'components/map-boundary-layer'
import MapFieldsOverview from 'components/map-fields-overview'
import MapProvider from 'components/map-provider'
import BoundaryTool from 'components/boundary-tool'
import HarvestToolbar from 'components/harvest-toolbar'
import MapNotesLayer from 'components/map-notes-layer'
import LayerLegend from 'components/layer-legend'
import AccountDisabledPage from 'components/account-disabled'
import { toGeoJson } from 'common/ol/utils'
import Collection from 'ol/Collection'
import ModifyInteraction from 'components/map-modify-interaction'
import DrawInteraction from 'components/map-draw-interaction'
import DrawBoxInteraction from 'components/map-draw-box-interaction'
import PointInfo from 'components/map/point-info'
import AddNote from 'components/add-note'
import { fromGeoJson } from 'common/ol/utils'
import {
  removeFeatures,
  fetchFeatures,
  saveFeatures,
  editFeatures,
  getLayerInfoByHarvest,
} from 'api/field'
import { getHarvestFeatures } from 'api/harvest'
import * as c from 'common/c'
import { getPartner } from 'common/misc'
import SwitchSplit from 'components/switch-split'
import { useEffect, useState } from 'react'
import useMap from 'hooks/use-map'
import OnMapHover from 'components/map/field-hover'
import { getArea } from 'ol/sphere.js'
import { convertAreaToPref } from 'common/unitConvert'

//Actions
import { pointInfoClr } from 'actions/map'
import { showPopup } from 'actions/app'
import { fieldFetched } from 'actions/field'
import { showModal } from 'actions/app'
import {
  stopEditField,
  areaInfoReq,
  switchDrawType,
  stopDrawingBoundary,
  startEditBoundary,
  stopEditBoundary,
  boundarySaved,
} from 'actions/map'

export default function MapPanel() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const cffEnabled = useSelector((state) =>
    get(state, 'account.singleton.prefs.cff.enabled', false),
  )
  const client = useSelector((state) => get(state, `client.singleton`))
  const farms = useSelector((state) => get(state, `farm.selectedFarms`))

  const tenant = useSelector((state) => state.account.singleton)
  const userPrefs = useSelector((state) => state.account.singleton.prefs)
  const field = useSelector((state) => get(state, 'field.singleton'))
  const fieldEdit = useSelector((state) => get(state, 'map.editField', false))
  const drawBoundary = useSelector((state) => get(state, 'map.drawBoundary'))
  const editBoundary = useSelector((state) => get(state, 'map.editBoundary'))
  const drawHole = useSelector((state) => get(state, 'map.drawHole'))
  const drawType = useSelector((state) => get(state, 'map.drawType'))
  const fieldFeatures = useSelector((state) => get(state, 'field.features'))
  const defaultExtent = useSelector((state) =>
    get(state, 'field.defaultExtent'),
  )
  const layers = useSelector((state) => get(state, 'field.layers'))
  const harvest = useSelector((state) => state.harvest.current)
  const harvests = useSelector((state) => state.harvest.collection)
  const harvestFeaturesArr = useSelector((state) => state.harvest.features)
  const legendUrls = useSelector((state) => get(state, 'map.legendUrl'))
  const wmsLayers = useSelector((state) => get(state, 'map.wmsLayers'))

  const featuresOverviewArr = useSelector((state) =>
    get(state, 'field.featuresOverview', []),
  )

  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [unselectedFeatures, setUnselectedFeatures] = useState([])

  const [showPointInfo, setShowPointInfo] = useState(false)
  const [showAreaInfo, setShowAreaInfo] = useState(false)
  const [showAddNote, setShowAddNote] = useState(false)
  const fields = useSelector((state) => get(state, 'field.collection', []))
  const [featureCollection, setFeatureCollection] = useState(undefined)
  const [originalFeature, setOriginalFeature] = useState(undefined)
  const [filteredWMSLayers, setFilteredWMSLayers] = useState([])
  const [filteredLegends, setFilteredLegends] = useState([])

  const unsavedChanges = useSelector((state) =>
    get(state, 'map.unsavedBoundary'),
  )
  const map = useMap()

  const ref = useRef()
  const subscriptions = useSelector((state) => state.account.subscriptions)
  const appState = useSelector((state) => get(state, 'app.state'))

  useEffect(() => {
    if (cffEnabled && (farms?.length || client) && field) {
      let filteredLayers = []
      let filteredUrls = []
      let selectedFarms = []
      if (farms?.length) {
        selectedFarms = farms
      } else if (client) {
        selectedFarms = client.farms
      }
      if (selectedFarms?.length && harvests?.length) {
        harvests.map((harvest) => {
          const fieldIndex = findIndex(fields, (field) => {
            return field.id === harvest.fieldId
          })
          const farmIndex = findIndex(selectedFarms, (farm) => {
            return farm.id === field.farmId
          })
          if (fieldIndex > -1 && farmIndex > -1) {
            const layer = wmsLayers.filter((l) => {
              return l.harvest === harvest.id
            })
            const url = legendUrls.filter((u) => {
              return u.harvest === harvest.id
            })
            if (layer?.length) {
              filteredLayers = filteredLayers.concat(layer)
            }
            if (url?.length) {
              filteredUrls = filteredUrls.concat(url)
            }
          }
        })
        setFilteredWMSLayers(filteredLayers)
        setFilteredLegends(filteredUrls)
      } else if (wmsLayers.length > 0) {
        setFilteredWMSLayers(wmsLayers)
        setFilteredLegends(legendUrls)
      } else {
        setFilteredWMSLayers([])
        setFilteredLegends([])
      }
    } else if (wmsLayers.length > 0) {
      setFilteredWMSLayers(wmsLayers)
      setFilteredLegends(legendUrls)
    } else {
      setFilteredWMSLayers([])
      setFilteredLegends([])
    }
  }, [harvests, fields, cffEnabled, client, farms, wmsLayers, legendUrls])

  useEffect(() => {
    if (localStorage.getItem(c.SUBSCRIPTIONS_POPUP_NOTIFICATION) === null) {
      if (subscriptions && subscriptions.length) {
        let subscriptionPeriods = subscriptions[0]['subscriptionPeriods']
        let maxDate = new Date(
          Math.max(...subscriptionPeriods.map((e) => new Date(e.startDate))),
        )
          .toISOString()
          .split('T')[0]
        let sp = subscriptionPeriods.find(
          (s) =>
            s.startDate === maxDate &&
            (s.subscriptionStatus === 'payment_due' ||
              s.subscriptionStatus === 'payment_due_final'),
        )
        if (sp) {
          dispatch(
            showModal(
              <div>
                {t('subscription_renewal_notification')},
                <a href="/admin/subscription"> {t('here')}</a>
              </div>,
              {
                title: t('subscription_renewal_notification_header'),
              },
            ),
          )
          localStorage.setItem(
            c.SUBSCRIPTIONS_POPUP_NOTIFICATION,
            JSON.stringify(false),
          )
        }
      }
    }
  }, [subscriptions])

  useEffect(() => {
    if (localStorage.getItem(c.ACCOUNT_DISABLED_POPUP_NOTIFICATION) === null) {
      if (appState === c.APP_STATE.ACCOUNT_DISABLED) {
        dispatch(
          showModal(<AccountDisabledPage partner={getPartner()} />, {
            title: t('account_deactivated_title'),
          }),
        )
        localStorage.setItem(
          c.ACCOUNT_DISABLED_POPUP_NOTIFICATION,
          JSON.stringify(false),
        )
      }
    }
  })

  useEffect(() => {
    if (harvest) {
      dispatch(getLayerInfoByHarvest(harvest.id, harvest.crop_year))
    }
  }, [harvest])

  // on field select
  useEffect(() => {
    if (field && field.id) {
      setFeatureCollection(new Collection([]))
      dispatch(fetchFeatures(field.id))
    }
  }, [field])

  // parse fetched features
  useEffect(() => {
    //setModifySelectedFeatures(false)
    setSelectedFeatures([])

    const featuresArr = []

    if (fieldFeatures && fieldFeatures !== undefined && !unsavedChanges) {
      fieldFeatures.forEach((item) => {
        const id = item?.fieldId || item.id
        if (id === field?.id) {
          featuresArr.push(
            Object.assign({}, { type: 'Feature' }, { ...item }, { id: id }),
          )
        }
      })
    }
    let newFeature = fromGeoJson({
      type: 'FeatureCollection',
      features: featuresArr,
    })
    newFeature.forEach((feature) => {
      const styleName = feature.get('style')
      if (!isNil(styleName)) {
        const selectStyleToApply = get(colorList, `${styleName}.select`)
        feature.setStyle(editBoundary ? modifyStyles : selectStyleToApply)
      } else {
        feature.setStyle(editBoundary ? modifyStyles : defaultStyle)
      }
    })
    if (featuresArr.length > 0 || fieldFeatures === undefined) {
      if (cffEnabled && (farms?.length || client)) {
        let filteredFeatures = new Collection([])
        let selectedFarms = []
        if (farms?.length) {
          selectedFarms = farms
        } else if (client) {
          selectedFarms = client.farms
        }
        if (selectedFarms?.length && featuresArr?.length) {
          featuresArr.map((feature) => {
            const fieldIndex = findIndex(fields, (field) => {
              return field.id === feature.fieldId
            })
            const farmIndex = findIndex(selectedFarms, (farm) => {
              return farm.id === field.farmId
            })
            if (fieldIndex > -1 && farmIndex > -1) {
              newFeature.forEach((f) => {
                if (f.getId() == feature.id) {
                  filteredFeatures.push(f)
                }
              })
            }
          })
          setFeatureCollection(filteredFeatures)
        } else {
          setFeatureCollection(newFeature)
        }
      } else {
        setFeatureCollection(newFeature)
      }
    }
  }, [
    fieldFeatures,
    field,
    editBoundary,
    unsavedChanges,
    farms,
    client,
    cffEnabled,
  ])

  // features overview
  const featuresOverview = useMemo(() => {
    const allFeaturesArr = []

    if (featuresOverviewArr && featuresOverviewArr !== undefined) {
      featuresOverviewArr.forEach((item) => {
        allFeaturesArr.push(Object.assign({}, { type: 'Feature' }, { ...item }))
      })
    }
    return fromGeoJson({
      type: 'FeatureCollection',
      features: allFeaturesArr,
    })
  }, [featuresOverviewArr])

  const harvestFeatures = useMemo(() => {
    const allFeaturesArr = []
    if (harvestFeaturesArr && harvestFeaturesArr.size !== 0) {
      const arr = Array.from(harvestFeaturesArr.values())
      if (arr && harvestFeaturesArr !== c.UNDEFINED) {
        arr.forEach((item) => {
          allFeaturesArr.push(
            Object.assign({}, { type: 'Feature' }, { ...item }),
          )
        })
      }
    }
    return fromGeoJson({
      type: 'FeatureCollection',
      features: allFeaturesArr,
    })
  }, [harvestFeaturesArr])

  const onBoundarySelect = useCallback(
    (selected, unselected = []) => {
      if (showPointInfo || showAreaInfo) return
      const selectedField = selected[0]
      const id = selectedField?.getId()
      if (id) {
        const field = find(fields, (o) => o.id === id)
        if (field) {
          dispatch(fieldFetched(field))
          dispatch(fetchFeatures(id))
        }
      }
      setSelectedFeatures(selected)
      setUnselectedFeatures(unselected)
      selected[0]?.setStyle(selectStyle)
    },
    [fields, showPointInfo, showAreaInfo],
  )

  const selectedFeatureCollection = useMemo(() => {
    return new Collection(selectedFeatures)
  }, [selectedFeatures])

  const harvestSelected = useMemo(() => {
    return !isNil(harvest)
  }, [harvest])

  useEffect(() => {
    if (harvest && harvest.id) {
      dispatch(getHarvestFeatures(harvest.id))
    }
  }, [harvest, showAddNote])

  useEffect(() => {
    selectedFeatureCollection.forEach((feature) => {
      const styleName = feature.get('style')
      if (!isNil(styleName)) {
        const selectStyleToApply = get(colorList, `${styleName}.select`)
        feature.setStyle(editBoundary ? modifyStyles : selectStyleToApply)
      } else {
        feature.setStyle(editBoundary ? modifyStyles : defaultStyle)
      }
    })
  }, [selectedFeatureCollection, editBoundary])

  useEffect(() => {
    unselectedFeatures.forEach((feature) => {
      const styleName = feature.get('style')
      if (!isNil(styleName)) {
        feature.setStyle(get(colorList, `${styleName}.normal`))
      } else {
        feature.setStyle(modifyStyles)
      }
    })
  }, [unselectedFeatures])

  // Field Boundary
  const boundary = useMemo(() => {
    let _boundary
    if (!isEmpty(featureCollection)) {
      const fArr = featureCollection?.getArray()
      for (let i = 0; i !== fArr.length; i++) {
        const f = fArr[i]
        if (
          f.get(c.OL_PROP_TYPE) === c.FTYPE_BOUNDARY ||
          isNil(f.get(c.OL_PROP_TYPE))
        ) {
          _boundary = f
          const styleName = _boundary.get('style')
          if (!isNil(styleName)) {
            const selectStyleToApply = get(colorList, `${styleName}.select`)
            _boundary.setStyle(editBoundary ? modifyStyles : selectStyleToApply)
          } else {
            _boundary.setStyle(editBoundary ? modifyStyles : defaultStyle)
          }
          break
        }
      }
    }

    return _boundary
  }, [featureCollection])

  const switchToDrawTypePolygon = useCallback(() => {
    const _originalFeature = cloneDeep(featureCollection)

    if (_originalFeature != undefined) {
      setOriginalFeature(_originalFeature)
    }
    dispatch(switchDrawType('MultiPolygon'))
  })

  const switchToDrawTypeCircle = useCallback(() => {
    const _originalFeature = cloneDeep(featureCollection)

    if (_originalFeature != undefined) {
      setOriginalFeature(_originalFeature)
    }
    dispatch(switchDrawType('Circle'))
  })

  const removeBoundary = useCallback(() => {
    dispatch(removeFeatures(field.id))
  }, [field])

  const modifyBoundary = useCallback(() => {
    const _originalFeature = cloneDeep(featureCollection)
    if (isEmpty(selectedFeatures)) {
      setSelectedFeatures(featureCollection)
    }
    if (_originalFeature != undefined) {
      if (!unsavedChanges) {
        setOriginalFeature(_originalFeature)
      }
      dispatch(startEditBoundary())
    }
  }, [selectedFeatures, featureCollection, unsavedChanges])

  const fieldSelected = useMemo(() => {
    return !isNil(field)
  }, [field, featuresOverview])

  const doneModifyBoundary = useCallback(() => {
    let area = 0
    featureCollection.forEach(function (feature) {
      area += getArea(feature.getGeometry())
    })
    if (area <= c.MAX_FIELD_SIZE_SQM) {
      if (harvests.length > 0) {
        dispatch(
          editFeatures(field.id, toGeoJson(featureCollection), harvests[0].id),
        )
      } else {
        if (featureCollection != undefined) {
          dispatch(saveFeatures(field.id, toGeoJson(featureCollection)))
        }
      }

      dispatch(boundarySaved())
      dispatch(stopEditField())
      setOriginalFeature(undefined)
    } else {
      const convertedArea = Math.floor(
        convertAreaToPref(area, userPrefs['units']['area_in']),
      )
      const maxArea = Math.floor(
        convertAreaToPref(c.MAX_FIELD_SIZE_SQM, userPrefs['units']['area_in']),
      )
      dispatch(
        showModal(
          <div>
            {t('field_edit_error', { field_name: field.name })}
            <br />
            {'- '}
            {t('field_too_large', {
              max_field_area: maxArea,
              field_area: convertedArea,
              units: userPrefs['units']['area_in'],
            })}
          </div>,
          {
            title: t('field_boundary_error'),
          },
        ),
      )
    }
  }, [field, featureCollection, harvests])

  const cancelModifyBoundary = useCallback(() => {
    if (unsavedChanges && originalFeature) {
      const originalFeaturesArray = originalFeature.getArray()
      featureCollection.forEach((feature) => {
        const id = feature.getId()
        for (let i = 0; i < originalFeaturesArray.length; i++) {
          const oId = originalFeaturesArray[i].getId()
          if (oId == id) {
            feature.setGeometry(originalFeaturesArray[i].getGeometry())
          }
        }
      })

      setFeatureCollection(originalFeature)
    }
    setOriginalFeature(undefined)
    dispatch(stopEditBoundary())
    dispatch(stopEditField())

    dispatch(boundarySaved())
  }, [field, featureCollection, map, originalFeature, unsavedChanges])

  const onBoundaryDraw = useCallback(
    (feature) => {
      featureCollection.forEach((item) => {
        const id = item.getId()
        if (id == field.id) {
          item.setGeometry(feature.getGeometry())
        }
      })
      if (!feature.getId()) {
        feature.setId(field.id)
      }

      feature.set(c.OL_PROP_TYPE, c.FTYPE_BOUNDARY)

      // TODO: temporarily to impose 1 polygon limit. When removed use saveBoundary() instead
      if (field.id && feature) {
        let _newFeature = feature
        if (!(feature instanceof Collection)) {
          _newFeature = new Collection([feature])
        }
        dispatch(stopDrawingBoundary())
        setFeatureCollection(_newFeature)
      }
    },
    [field, featureCollection],
  )

  const onHoleDraw = useCallback(
    (feature) => {
      featureCollection.forEach((item) => {
        const id = item.getId()
        if (id == field.id) {
          item.setGeometry(feature.getGeometry())
        }
      })
      if (!feature.getId()) {
        feature.setId(field.id)
      }

      feature.set(c.OL_PROP_TYPE, c.FTYPE_BOUNDARY)

      // TODO: temporarily to impose 1 polygon limit. When removed use saveBoundary() instead
      if (field.id && feature) {
        let _newFeature = feature
        if (!(feature instanceof Collection)) {
          _newFeature = new Collection([feature])
        }
        setFeatureCollection(_newFeature)
      }
    },
    [featureCollection, drawHole],
  )

  const isEditing = useMemo(() => {
    const _originalFeature = cloneDeep(featureCollection)

    if (_originalFeature != undefined) {
      setOriginalFeature(_originalFeature)
    }

    if (featureCollection) {
      featureCollection.forEach((feature) => {
        const styleName = feature.get('style')
        if (!isNil(styleName)) {
          const selectStyleToApply = get(colorList, `${styleName}.select`)
          feature.setStyle(editBoundary ? modifyStyles : selectStyleToApply)
        } else {
          feature.setStyle(editBoundary ? modifyStyles : defaultStyle)
        }
      })
    }
    return drawBoundary || drawHole || editBoundary
  }, [drawBoundary, drawHole, editBoundary, featureCollection, unsavedChanges])

  const showOverview = useMemo(() => {
    return featureCollection?.getLength() === 0 && isNil(field)
  }, [featureCollection, field])

  const focusActive = useMemo(() => {
    return !(isNil(field) || isNil(boundary)) || showOverview
  }, [field, boundary, showOverview])

  const onPointClick = useCallback(() => {
    setShowAddNote(false)
    setShowAreaInfo(false)
    dispatch(pointInfoClr())

    setShowPointInfo(!showPointInfo)
    // setShowOverrideCropType(false)
  }, [showPointInfo])

  const onAreaClick = useCallback(() => {
    setShowAddNote(false)
    setShowPointInfo(false)
    setShowAreaInfo(true)
    // setShowOverrideCropType(false)
  })

  const onAddNote = useCallback(() => {
    setShowPointInfo(false)
    setShowAreaInfo(false)

    setShowAddNote(!showAddNote)
    // setShowOverrideCropType(false)
  }, [showAddNote])

  const onDrawBox = useCallback(
    (extent, pixel) => {
      dispatch(areaInfoReq(extent))
      dispatch(
        showPopup(<AreaInfoView />, {
          title: 'Area Info',
          pixel,
        }),
      )
      setShowAreaInfo(false)
    },
    [showAreaInfo],
  )
  const harvestId = harvest?.id
  filteredWMSLayers.sort((a, b) => a.zindex - b.zindex)
  return (
    <MapProvider
      onSelect={onBoundarySelect}
      selectActive={true}
      editing={isEditing}
    >
      <SidePanel zindex={2}>
        <SidePanelFields />
      </SidePanel>
      <PageContent zindex={1}>
        <ErrorBoundary trans={t}>
          <OnMapHover />
          <MapSourceBoundaryProvider features={featuresOverview}>
            {MAP_PROVIDER === 'google' ? <GoogleMaps /> : <BingMaps />}
            {(harvestId && layers[harvestId]?.boundary.selected) ||
            editBoundary ? (
              <MapBoundaryLayer
                features={featureCollection}
                modify={editBoundary}
              />
            ) : null}
            <MapFieldsOverview features={featuresOverview} />
            {editBoundary ? (
              <ModifyInteraction features={featureCollection} />
            ) : null}
            {onBoundaryDraw ? (
              <DrawInteraction
                withMemory={false}
                onDraw={onBoundaryDraw}
                active={drawBoundary}
                type={drawType}
              />
            ) : null}
            {onHoleDraw ? (
              <DrawInteraction
                withMemory={false}
                onDraw={onHoleDraw}
                active={drawHole}
                type={drawType}
              />
            ) : null}
            <FocusField
              boundary={boundary}
              defaultExtent={defaultExtent}
              active={focusActive}
            />
            {filteredWMSLayers.length > 0
              ? filteredWMSLayers.map((layerData) => (
                  <MapWMSLayer
                    key={`${layerData.name}_${layerData.harvest}`}
                    layersName={layerData.name}
                    bbox={layerData.bbox}
                    legendUrl={layerData.legend}
                    harvestId={layerData.harvest}
                    tenantId={tenant.id}
                    mapRef="map"
                    layerZIndex={layerData.zindex}
                  />
                ))
              : null}
            <FarmtrxMap ref={ref} />
            {showPointInfo ? <PointInfo /> : null}
            {showAreaInfo ? <DrawBoxInteraction onDrawBox={onDrawBox} /> : null}
            {showAddNote ? <AddNote /> : null}
            {showAddNote ? (
              <MapNotesLayer featureCollection={harvestFeatures} />
            ) : null}
            <>
              {fieldEdit || isEditing ? (
                <BoundaryTool
                  onEdit={modifyBoundary}
                  onDelete={removeBoundary}
                  onDrawPolygon={switchToDrawTypePolygon}
                  onDrawCircle={switchToDrawTypeCircle}
                  onDone={doneModifyBoundary}
                  onCancel={cancelModifyBoundary}
                  drawTypeSelected={drawType}
                  features={featureCollection}
                />
              ) : fieldSelected &&
                harvestSelected &&
                filteredWMSLayers &&
                filteredWMSLayers.length ? (
                <HarvestToolbar
                  pointInfo={showPointInfo}
                  onPointInfo={onPointClick}
                  areaInfo={showAreaInfo}
                  onAreaInfo={onAreaClick}
                  addNote={showAddNote}
                  onAddNote={onAddNote}
                />
              ) : null}
              {fieldSelected && harvestId ? (
                <FieldInfo field={field} harvest={harvest} />
              ) : null}
              {filteredLegends.length > 0 ? (
                <LayerLegend
                  key={'legendPanel'}
                  url={filteredLegends}
                  harvestId={harvest.id}
                  lpVersion={harvest?.stats?.lp_version}
                />
              ) : null}
              {fieldSelected &&
              window.screen.width >= 1000 &&
              window.screen.height >= 800 ? (
                <SwitchSplit />
              ) : null}
            </>
          </MapSourceBoundaryProvider>
        </ErrorBoundary>
      </PageContent>
    </MapProvider>
  )
}
