import { isNil, get } from 'lodash-es'
import SplitMapProvider from 'components/split-map-provider'
import GoogleSplitMaps from 'components/map/google-split-maps'
import MapWMSLayer from 'components/map-wms-layer'
import FarmtrxMapA from 'components/map/farmtrx-map-a'
import FarmtrxMapB from 'components/map/farmtrx-map-b'
import SplitPanel from 'components/split-panel'
import FocusFieldA from 'components/map/focus-field-a'
import FocusFieldB from 'components/map/focus-field-b'
import LayerLegend from 'components/layer-legend'
import FieldInfo from 'components/field-info'
import LockView from './lock-view'
import { fromGeoJson } from 'common/ol/utils'
import {
  setYearA,
  setYearB,
  fieldFetchedA,
  fieldFetchedB,
  resetLayersA,
  resetLayersB,
} from 'actions/field'
import { harvestAFetched, harvestBFetched } from 'actions/harvest'
import {
  findHarvestsByFieldYearCropA,
  findHarvestsByFieldYearCropB,
  findHarvestsByFieldA,
  findHarvestsByFieldB,
} from 'api/harvest'
import {
  hideLayerLegendA,
  removeLayerA,
  showLayerA,
  hideLayerLegendB,
  removeLayerB,
  showLayerB,
  removeLayerLegendsA,
  removeLayerLegendsB,
} from 'actions/map'

import {
  getLayerInfoAByHarvest,
  getLayerInfoBByHarvest,
  fetchFeaturesA,
  fetchFeaturesB,
  filterFieldsCFFA,
  filterFieldsCFFB,
} from 'api/field'
import * as c from 'common/c'
import { setLayersA, setLayersB } from 'actions/field'
import { useSelector } from 'react-redux'
import SwitchSingle from 'components/switch-single'
//import { SplitGridWrapper } from 'components/page'

export default function SplitMapPanel() {
  const ref_a = useRef()

  const ref_b = useRef()

  const fieldA = useSelector((state) => get(state, 'field.field_a'))
  const fieldB = useSelector((state) => get(state, 'field.field_b'))

  const defaultExtent = useSelector((state) =>
    get(state, 'field.defaultExtent'),
  )

  const harvestA = useSelector((state) => state.harvest.current_a)

  const harvestB = useSelector((state) => state.harvest.current_b)

  const legendUrlA = useSelector((state) => get(state, 'map.legendUrl_a'))

  const legendUrlB = useSelector((state) => get(state, 'map.legendUrl_b'))

  const wmsLayersA = useSelector((state) => get(state, 'map.wmsLayersA'))

  const wmsLayersB = useSelector((state) => get(state, 'map.wmsLayersB'))
  const featuresA = useSelector((state) => get(state, 'field.features_a'))
  const featuresB = useSelector((state) => get(state, 'field.features_b'))

  const harvestsA = useSelector((state) => state.harvest.collection_a)

  const harvestsB = useSelector((state) => state.harvest.collection_b)

  // parse fetched features
  const featureCollectionA = useMemo(() => {
    const featuresArr = []

    if (featuresA && featuresA !== undefined) {
      featuresA.forEach((item) => {
        featuresArr.push(Object.assign({}, { type: 'Feature' }, { ...item }))
      })
    }

    return fromGeoJson({
      type: 'FeatureCollection',
      features: featuresArr,
    })
  }, [featuresA])
  // parse fetched features
  const featureCollectionB = useMemo(() => {
    const featuresArr = []

    if (featuresB && featuresB !== undefined) {
      featuresB.forEach((item) => {
        featuresArr.push(Object.assign({}, { type: 'Feature' }, { ...item }))
      })
    }

    return fromGeoJson({
      type: 'FeatureCollection',
      features: featuresArr,
    })
  }, [featuresB])

  const boundaryA = useMemo(() => {
    let _boundary
    const fArr = featureCollectionA.getArray()
    for (let i = 0; i !== fArr.length; i++) {
      const f = fArr[i]
      if (
        f.get(c.OL_PROP_TYPE) === c.FTYPE_BOUNDARY ||
        isNil(f.get(c.OL_PROP_TYPE))
      ) {
        _boundary = f
        break
      }
    }

    return _boundary
  }, [featureCollectionA])

  const boundaryB = useMemo(() => {
    let _boundary
    const fArr = featureCollectionB.getArray()
    for (let i = 0; i !== fArr.length; i++) {
      const f = fArr[i]
      if (
        f.get(c.OL_PROP_TYPE) === c.FTYPE_BOUNDARY ||
        isNil(f.get(c.OL_PROP_TYPE))
      ) {
        _boundary = f
        break
      }
    }

    return _boundary
  }, [featureCollectionB])

  const focusActiveA = useMemo(() => {
    return !(isNil(fieldA) || isNil(boundaryA))
  }, [fieldA, boundaryA])

  const focusActiveB = useMemo(() => {
    return !(isNil(fieldB) || isNil(boundaryB))
  }, [fieldB, boundaryB])

  return (
    <SplitMapProvider>
      <GoogleSplitMaps />
      <FocusFieldA
        boundary={boundaryA}
        defaultExtent={defaultExtent}
        active={focusActiveA}
      />
      <FocusFieldB
        boundary={boundaryB}
        defaultExtent={defaultExtent}
        active={focusActiveB}
      />
      <FarmtrxMapA ref={ref_a}>
        <SplitPanel
          harvestKey={'harvest.current_a'}
          layersKey={'layers_a'}
          layerInfoKey={'layerInfo_a'}
          harvestYear={'year_a'}
          fieldsHarvestedKey={'fieldsHarvested_a'}
          harvests={'harvest.collection_a'}
          setYear={setYearA}
          setField={fieldFetchedA}
          findHarvestsByField={findHarvestsByFieldA}
          findHarvestsByFieldYearCrop={findHarvestsByFieldYearCropA}
          filterFieldsCFF={filterFieldsCFFA}
          setLayers={setLayersA}
          getLayerInfoByHarvest={getLayerInfoAByHarvest}
          showLayer={showLayerA}
          hideLayerLegend={hideLayerLegendA}
          removeLayer={removeLayerA}
          harvestFetched={harvestAFetched}
          fetchFeatures={fetchFeaturesA}
          resetLayers={resetLayersA}
          removeLayerLegends={removeLayerLegendsA}
        />
        <LockView lockViewRef={'lock_a'} />
        {legendUrlA.length > 0 ? (
          <LayerLegend
            key={'legendPanelA'}
            url={legendUrlA}
            harvestId={harvestA.id}
            lpVersion={harvestA?.stats?.lp_version}
          />
        ) : null}
        {wmsLayersA.length > 0
          ? wmsLayersA.map((layerData) => (
              <MapWMSLayer
                key={`${layerData.name}_${layerData.harvest}`}
                layersName={layerData.name}
                bbox={layerData.bbox}
                legendUrl={layerData.legend}
                harvestId={layerData.harvest}
                tenantId={fieldA.tenantId}
                mapRef="map_a"
              />
            ))
          : null}
        {harvestsA.length && harvestA?.id ? (
          <FieldInfo field={fieldA} harvest={harvestA} />
        ) : null}
      </FarmtrxMapA>
      <FarmtrxMapB ref={ref_b}>
        <SplitPanel
          harvestKey={'harvest.current_b'}
          layersKey={'layers_b'}
          layerInfoKey={'layerInfo_b'}
          harvestYear={'year_b'}
          fieldsHarvestedKey={'fieldsHarvested_b'}
          harvests={'harvest.collection_b'}
          setYear={setYearB}
          setField={fieldFetchedB}
          findHarvestsByField={findHarvestsByFieldB}
          findHarvestsByFieldYearCrop={findHarvestsByFieldYearCropB}
          filterFieldsCFF={filterFieldsCFFB}
          setLayers={setLayersB}
          getLayerInfoByHarvest={getLayerInfoBByHarvest}
          showLayer={showLayerB}
          hideLayerLegend={hideLayerLegendB}
          removeLayer={removeLayerB}
          harvestFetched={harvestBFetched}
          fetchFeatures={fetchFeaturesB}
          resetLayers={resetLayersB}
          removeLayerLegends={removeLayerLegendsB}
        />
        <SwitchSingle />
        <LockView lockViewRef={'lock_b'} />
        {legendUrlB.length > 0 ? (
          <LayerLegend
            key={'legendPanelB'}
            url={legendUrlB}
            harvestId={harvestB.id}
            lpVersion={harvestB?.stats?.lp_version}
          />
        ) : null}
        {wmsLayersB.length > 0
          ? wmsLayersB.map((layerData) => (
              <MapWMSLayer
                key={`${layerData.name}_${layerData.harvest}`}
                layersName={layerData.name}
                bbox={layerData.bbox}
                legendUrl={layerData.legend}
                harvestId={layerData.harvest}
                tenantId={fieldB.tenantId}
                mapRef="map_b"
              />
            ))
          : null}
        {harvestsB.length && harvestB?.id ? (
          <FieldInfo field={fieldB} harvest={harvestB} />
        ) : null}
      </FarmtrxMapB>
    </SplitMapProvider>
  )
}
